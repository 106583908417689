/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import NavbarV2 from "../../../component/navbar-v2.js";
import Footer from "../../../component/footer.js";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import styles from './mlearning.css'

export default function MLearning() {
    const backend_url  = process.env.REACT_APP_BACKEND_URL;
    const [historic, setHistoric] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selected_categorie, setSelectedCategories] = useState(undefined);
    const [selected_intent, setSelectedIntent] = useState(undefined);
    const [options_categorie, setOptionsCategories] = useState([]);
    const [options_intent, setOptionsIntents] = useState([]);



    function categorieSelected(item){
        setSelectedCategories(item.value);
        const categorie = categories.find((cat) => cat.nom_fr === item.value);
        console.log(item);
        console.log(selected_categorie);
        let options =[];
        categorie.intents.map((intent)=>{
            options.push({
                value: intent.nom_fr,
                label: intent.nom_fr
            })
        });
        setOptionsIntents(options);
    }

    function intentSelected(item){
        setSelectedIntent(item.value);
    }

    function jeValide(historic){
        if ( historic.bonne_categorie === undefined || historic.bonne_categorie === "" ){
            toast.error("Vous devez definir la bonne catégorie d'abord");
            return
        }
        if (historic.bonne_intent === undefined || historic.bonne_intent === ""){
            toast.error("Vous devez definir la bonne intent d'abord");
            return
        }
        
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'PUT',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        
        },
          body: JSON.stringify({ 
           ...historic
            
          },)};
        
        fetch(backend_url+'/historic_chatbot/validate/'+historic.id, requestOptions)
        .then(response => {

            if (response.ok) {
                toast.success("Validation effectuée avec succès !");
              }
            return response.json();
        })
    }
    function sendModification(historic){
        if (selected_categorie === undefined){
            toast.error("Vous devez choisir une catégorie");
            return
        }
        if (selected_intent === undefined){
            toast.error("Vous devez choisir un intent");
            return
        }
        historic.bonne_categorie = selected_categorie;
        historic.bonne_intent = selected_intent;

        const categorie = categories.find((cat) => cat.nom_fr === selected_categorie);
        const intent = categorie.intents.find((intent) => intent.nom_fr === selected_intent);

        if (intent === undefined){
            toast.error("Merci de vérifier vos input, l'intent ne correspond pas à la catégorie");
            return
        }
    
        console.log(historic);

        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'PUT',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        
        },
          body: JSON.stringify({ 
           ...historic
            
          },)};
        
        fetch(backend_url+'/historic_chatbot/'+historic.id, requestOptions)
        .then(response => {

            if (response.ok) {
                toast.success("Mise à jour de l'input effectuée avec succès!");
              }
            return response.json();
        })
    }

    function getCategories(){
         
        let token = localStorage.getItem("token");        
    
        const requestOptions = {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        }
        };
        
        fetch(backend_url+'/categories', requestOptions)
        .then(response => {
            return response.json();
        })
        .then(data => {
          setCategories(data);
        })
        .catch(error => {
         console.log(error);
        });
    }

    function getAllHistory(){
         
        let token = localStorage.getItem("token");        
    
        const requestOptions = {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        }
        };
        
        fetch(backend_url+'/historic/all', requestOptions)
        .then(response => {
            return response.json();
        })
        .then(data => {
          setHistoric(data);
        })
        .catch(error => {
         console.log(error);
        });
    }

    useEffect(()=>{
        getAllHistory();
        getCategories();
    }, []);

    useEffect(()=>{
        console.log(historic);
    }, [historic]);

    useEffect(()=>{
        let options = []
        categories.map((categorie)=>{
            options.push({
                value: categorie.nom_fr,
                label: categorie.nom_fr
            })
        })
        setOptionsCategories(options);
    }, [categories]);

    

    
    return (
        <div className={styles}>
            <NavbarV2 />
            <ToastContainer />
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Centre d'apprentissage</h3>
                    </div>
                </div>
            </section>
            <section className="relative">
                <div className='contenu-principal-mlearning'>
                    {
                        historic.map((historic, index) =>{

                            return(
                                <div key={index} className='details-history'>
                                    <span>{historic.texte}</span>
                                    <div  className='details-history-more'>
                                        <div className='details-history-more-item'>
                                            <div><h1>Catégorie</h1></div>
                                            <div><p>{historic.categorie}</p></div>
                                        </div>
                                        <div className='details-history-more-item'>
                                            <div><h1>Intent</h1></div>
                                            <div><p>{historic.intent}</p></div>
                                        </div>
                                        <div className='details-history-more-item'>
                                            <div><h1>Score categorie</h1></div>
                                            <div><p>{historic.score_categorie}</p></div>
                                        </div>
                                        <div className='details-history-more-item'>
                                            <div><h1>Score intent</h1></div>
                                            <div><p>{historic.score_intent}</p></div>
                                        </div>
                                    </div>
                                    { historic.bonne_categorie !== "" &&
                                    <div  className='details-history-good-inputs'>
                                        <div className='details-history-more-item'>
                                            <div><h1>Bonne Catégorie</h1></div>
                                            <div><p>{historic.bonne_categorie}</p></div>
                                        </div>
                                        <div className='details-history-more-item'>
                                            <div><h1>Bonne Intent</h1></div>
                                            <div><p>{historic.bonne_intent}</p></div>
                                        </div>
                                        <div className='details-history-more-item'>
                                            
                                        </div>
                                        <div className='details-history-more-item'>
                                            <div style={{display: 'flex', float: 'right'}}>
                                                <button className="action-button valid-button btn-effect" onClick={()=> jeValide(historic)}>Je valide</button>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    <div className='details-history-actions' >
                                            <div>
                                                <Select 
                                                    label="Choix des catégories"
                                                    placeholder="Choix des catégories"
                                                    inputId='filtre-categorie'
                                                    name='type-categorie'
                                                    onChange={categorieSelected}
                                                    options={options_categorie} 
                                                />
                                            </div>
                                            <div>
                                                <Select 
                                                    label="Choix des intent"
                                                    placeholder="Choix des intent"
                                                    inputId='filtre-intent'
                                                    name='type-intent'
                                                    onChange={intentSelected}
                                                    options={options_intent} />
                                            </div>
                                            <div>

                                            </div>

                                            <div>
                                                <div style={{display: 'flex', float: 'right'}}>
                                                        <button className="action-button accept-button btn-effect" onClick={()=> sendModification(historic)}>Enregistrer</button>
                                    
                                                </div>
                                            </div>

                                    </div>
                                    

                                    

                                </div>
                            )
                        })
                    }

                </div>
                
            </section>
            
            <Footer />
        </div>
    )
}
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from "react-router-dom";
import { Hexagon } from 'react-feather';

export default function OurServices() {
    const services = [
        {
            icon: 'adjust-circle',
            title: 'Developpez votre business',
            description: "Maitrisez vos delais de livraison en étant en contact avec plusieurs transporteurs",
        },
        {
            icon: 'circuit',
            title: 'Augmentez vos ventes',
            description: "Faites connaitre vos differents services d'envois",
        },
        {
            icon: 'fire',
            title: 'Notifications',
            description: "Soyez au courant des trajets lorsqu'ils sont postés",
        },
       
    ];
    return (
        <section className="relative md:py-24 py-16 dark:bg-slate-800 bg-services" id="features">
            <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-2xl text-xl font-medium titre-home">Nos services</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">
                       Utiliser colismi c'est profiter de plusieurs services de qualité
                    </p>
            </div>
            <div className="container lg mx-auto">
               

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                    {services.map((service, index) => (
                        <div key={index} className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                            <div className="relative overflow-hidden text-transparent -m-3">
                                <Hexagon className="h-28 w-28 fill-violet-colismi mx-auto rotate-[30deg]"
                                ></Hexagon>
                                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                    <i className={`uil uil-${service.icon}`} style={{color: "#ffff"}}></i>
                                </div>
                            </div>
                            <div className="mt-6">
                                <Link to="/services" className="text-lg h5 transition duration-500 ease-in-out hover:text-violet-600 titre-home" >
                                    {service.title}
                                </Link>
                                <p className="description-about text-slate-400 transition duration-500 ease-in-out mt-3">
                                    {service.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
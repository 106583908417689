import  styles from "./travel-item.css";
import React from 'react';
import { Link } from "react-router-dom";
import profil from '../../../assets/images/colismi/trajet/user.svg';
import fleche from '../../../assets/images/colismi/trajet/fleche.svg';
import depart from '../../../assets/images/colismi/trajet/depart_couleur.svg';
import arrivee from '../../../assets/images/colismi/trajet/arrivee_couleur.svg';
import description from '../../../assets/images/colismi/trajet/description.svg';
import charge from '../../../assets/images/colismi/trajet/charge.svg';
import etoile from '../../../assets/images/colismi/trajet/etoile.svg';
import prix from '../../../assets/images/colismi/trajet/prix.svg';
import Boat from '@mui/icons-material/DirectionsBoat';
import FlightTakeoff from '@mui/icons-material/FlightTakeoff';



export default function TravelItemV2(data){
   
    


    // eslint-disable-next-line no-unused-vars
    function jeSuisInteresse(item){
        data.onClick();
    }
    return(
        <div className={styles}>
            <article className="trip-card" >
                <div className="trip-header">
                      {data.type === "Avion"  &&
                        <FlightTakeoff className='colismi-icon' sx={{ fontSize: 40, color: '#5f2972' }} />

                      }
                      {data.type === "Fret Aerien"  &&
                      <FlightTakeoff className='colismi-icon' sx={{ fontSize: 40, color: '#5f2972' }} />

                      }
                      {data.type === "Bateau"  &&
                        <Boat className='colismi-icon' sx={{ fontSize: 40, color: '#5f2972' }} />

                      }
                      <div className="trip-details">
                        <div className="trip-locations">
                          <div className="location-info-trajet">
                            <img loading="lazy" src={depart} className="location-icon" alt="Departure location icon" />
                            <div className="location-name-trajet">{data.ville_depart}</div>
                          </div>
                          <div className="trip-date">{data.date_depart}</div>
                        </div>
                        <img loading="lazy" src={fleche} className="trip-arrow" alt="Trip direction arrow" />
                        <div className="trip-locations">
                          <div className="location-info-trajet">
                            <img loading="lazy" src={arrivee} className="location-icon" alt="Arrival location icon" />
                            <div className="location-name-trajet">{data.ville_arrivee}</div>
                          </div>
                          <div className="trip-date">{data.date_arrivee}</div>
                        </div>
                      </div>
                </div>
                <div className="trip-body">
                      <ul className="trip-info-list">
                        <li className="trip-info-item">
                          <img loading="lazy" src={charge} className="info-icon" alt="Weight icon" />
                          { data.capacite < 0 &&
                            <div className="info-text">Capacité: Non renseignée</div>
                          }
                          { data.capacite > 0 &&
                            <div className="info-text">Capacité: {data.capacite}kg</div>
                          }
                          
                        </li>
                        <li className="trip-info-item">
                          {data.type === "Avion"  &&
                            <FlightTakeoff className='colismi-icon' sx={{ fontSize: 30, color: '#5f2972' }} />

                          }
                          {data.type === "Fret Aerien"  &&
                          <FlightTakeoff className='colismi-icon' sx={{ fontSize: 30, color: '#5f2972' }} />

                          }
                          {data.type === "Bateau"  &&
                            <Boat className='colismi-icon' sx={{ fontSize: 30, color: '#5f2972' }} />

                          }
                          <div className="info-text">Transport en {data.type}</div>
                        </li>
                        <li className="trip-info-item">
                          <img loading="lazy" src={prix} className="info-icon" alt="Price icon" />
                          { data.prix < 0 &&
                            <div className="info-text">Prix: non renseigné</div>
                          }
                           { data.prix > 0 &&
                            <div className="info-text">Prix: {data.prix}€/Kg</div>
                          }
                          
                        </li>
                      </ul>
                      <div className="trip-description">
                        <img loading="lazy" src={description} className="description-icon" alt="Description icon" />
                        <p className="description-text">{data.description}</p>
                      </div>
                </div>
                <div className="trip-footer">
                      <div className="footer-divider"></div>
                      <div className="footer-content">
                        <div className="user-info">
                          <img loading="lazy" src={profil} className="user-avatar" alt="User avatar" />
                          <div className="user-details">
                            <div className="user-name"><Link to="/traveler"  state={{ user: data.user }}> {data.user.pseudo}</Link></div>
                            <div className="user-rating">
                              <div className="rating-value hidden">4.5</div>
                              <img className="rating-star hidden" loading="lazy" src={etoile} alt="Rating star" />
                              <div className="rating-count hidden">(6)</div>
                            </div>
                          </div>
                        </div>
                        <div className="action-buttons">
                             <button  className="view-route-btn"><Link to={'/trajet/'+data.numero}  params={{ numero: data.numero }} state= {{  numero: data.numero }}> Voir le trajet</Link></button>
                             <button onClick={() => jeSuisInteresse(data)} className="interested-btn">Je suis intéressé</button>
                         </div>
                      </div>
                </div>
            </article>
        </div>
    );
}
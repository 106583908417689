/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <div>
            <footer className="footer-website bg-slate-950 relative text-gray-200 dark:text-gray-200">
                <div className="container">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12">
                            <div className="">
                                <div className="grid grid-cols-1">
                                    <div className="text-center">
                                        
                                        <p className="max-w-xl mx-auto text-slate-400 mt-8">Colismi vous accompagne pour
                                        envoyer vos colis à l'international. C'est simple, contactez un voyageur et donnez lui les détails
                                        de votre colis. Ou tout simplement créez une demande, les voyageurs vous recontacteront.
                                        </p>
                                    </div>

                                    <ul className="list-none footer-list text-center mt-8">
                                        <li className="inline px-2"><Link to="/services"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">Services</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/aboutus"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">A Propos
                                            </Link></li>
                                        <li className="inline px-2 mt-[10px] hidden"><Link to="/blogs"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Blog</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px] hidden"><Link to="/team"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Team</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px] hidden"><Link to="/pricing"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Pricing</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/contactus"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Contactez
                                            nous </Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-3 px-0 border-t border-slate-800">
                    <div className="container text-center">
                        <div className="">
                            <div className="md:col-span-6">
                                <div className="md:text-start text-center">
                                    <p className="text-gray-400 container">©
                                        {new Date().getFullYear()} Colismi
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import about from '../assets/images/colismi-intro.webp';
import './about.css'

export default function About() {
    return (
        <>
            <section className="relative md:py-24 py-16" id="about">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <img src={about} className="rounded-lg shadow-lg relative" alt="" />
                                <div className="absolute bottom-2/4 translate-y-2/4 end-0 start-0 text-center">
                                    {/* <Link to="#!" data-type="youtube" data-id="yba7hPeTSjk"
                                    className="lightbox h-20 w-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-950 text-violet-600">
                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-7">
                                <h3 className="mb-4 md:text-2xl text-xl font-medium titre-about"> A propos de Colismi</h3>

                                <p className=" max-w-2xl mx-auto description-about">Colismi est une plateforme innovante qui facilite la mise en relation entre particuliers pour le transport de colis. Conçue pour répondre aux besoins modernes de livraison, cette plateforme offre une solution économique, conviviale et écologique pour l'envoi et la réception de colis.

En utilisant Colismi, les utilisateurs peuvent proposer leurs services de transport de colis et conctacter les professionnels du transport. Que ce soit pour un petit envoi ou un colis volumineux à livrer à l'autre bout du monde, Colismi met en relation les expéditeurs et les transporteurs de manière efficace et sécurisée.

<br/>
<br/>
Grâce à son interface conviviale et à ses fonctionnalités intuitives, les utilisateurs peuvent facilement publier des annonces, rechercher des trajets disponibles, discuter avec les transporteurs potentiels et organiser les détails de la livraison en toute tranquillité.
<br/>
<br/>
Avec Colismi, le transport de colis devient non seulement plus accessible et abordable, mais aussi plus humain et respectueux de l'environnement. Que ce soit pour envoyer un cadeau à un proche, expédier un colis important ou simplement rendre service à un ami, Colismi offre une solution simple et efficace pour répondre à tous les besoins de livraison des particuliers.
                                </p>

                                <div className="relative mt-8">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
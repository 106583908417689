/* eslint-disable react/no-unescaped-entities */
import logo_light from '../assets/images/colismi_logo_small.png';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

export default function Signup() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [typeCompte, setTypeCompte] = useState("Particulier"); 
    var validator = require('validator');
    const options = [
        { value: 'Particulier', label: 'Particulier' },
        { value: 'Professionnel', label: 'Professionnel' },
        { value: 'Commercant', label: 'Commercant' },
        { value: 'Coursier', label: 'Coursier' }
      ]
    function register(){
      let accept_tc = document.getElementById("AcceptT&C").checked;
      console.log(accept_tc);
      if(!accept_tc){
            toast.error("Vous devez accepter les conditions d'utilisation");
        return
      }
      
      const pseudo = document.getElementById('RegisterName').value;
      const email = document.getElementById('LoginEmail').value;  
      const password = document.getElementById('LoginPassword').value; 
      if (!validator.isEmail(email)){
            toast.error("L'adresse mail renseignée n'est pas valide");
        return
      }

      if(pseudo == "" || email == "" || password == ""){
            toast.error("Tous les champs doivent être remplis");
        return
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          email: email,
          pseudo: pseudo,
          password: password,
          type: typeCompte
        })};
      
      fetch(backend_url+'/user/add', requestOptions)
      .then(response => {
        console.log(response);
        if (response.ok){
            toast.success("Votre compte a été crée avec succès");
             setTimeout(() => {
                navigate("/login");
              }, 2000);
            
              document.getElementById('RegisterName').value = "";
              document.getElementById('LoginEmail').value = "";  
              document.getElementById('LoginPassword').value = ""; 
            
        }
        return response.json();
      })
      .then(data => {
        if(data.detail != undefined){
            toast.error(data.detail);
        }
      })
      .catch(error => {
       console.log(error);
      });
    }

    function accountSelected(item){
        setTypeCompte(item.value);
    }

    return (
        <>
            <ToastContainer />
            <section className="position-relative bg-[url('../../assets/images/bg/bg-6.jpg')] bg-center bg-no-repeat" style={{backgroundSize:'cover'}}>
                <div className="absolute inset-0 bg-slate-950/50"></div>
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <div className="lg:col-span-4">
                            <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">

                                <div className="text-center mx-auto">
                                    <Link to="/index"><img src={logo_light} alt="" /></Link>
                                </div>

                                <div className="text-center my-auto">
                                    <div className="w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-gray-800">
                                        <div className="grid grid-cols-1">
                                            <h5 className="mb-8 text-xl dark:text-white font-medium">Créez votre compte</h5>
                                            <div className="text-start">
                                                <div className="grid grid-cols-1">
                                                    <div className="mb-4">
                                                        <label className="form-label font-medium" htmlFor="RegisterName">Pseudo:</label>
                                                        <input id="RegisterName" type="text" className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                                    </div>

                                                    <div className="mb-4">
                                                        <label className="form-label font-medium" htmlFor="LoginEmail">email:</label>
                                                        <input id="LoginEmail" type="email" className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="form-label font-medium" htmlFor="LoginPassword">Vous êtes:</label>
                                                        <Select 
                                                            label="Vous etes"
                                                            placeholder=""
                                                            inputId='visibilite-demande'
                                                            name='visibilite-demande'
                                                            onChange={accountSelected}
                                                            options={options} />
                                                        
                                                    </div>

                                                    <div className="mb-4">
                                                        <label className="form-label font-medium" htmlFor="LoginPassword">Mot de passe:</label>
                                                        <input id="LoginPassword" type="password" className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                                    </div>

                                                    <div className="mb-4">
                                                        <div className="flex items-center w-full mb-0">
                                                            <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-300 focus:ring focus:ring-offset-0 focus:ring-violet-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="AcceptT&C" />
                                                            <label className="form-check-label text-slate-400" htmlFor="AcceptT&C">J'accepte <Link to="/cgu"  target="_blank" className="text-violet-600">Les conditions d'utilisation</Link></label>
                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <button className="colismi-button w-full" onClick={register}>Créer mon compte</button>
                                                    </div>

                                                    <div className="text-center">
                                                        <span className="text-slate-400 me-2">Vous avez déjà un compte ?  </span> <Link to="/login" className="text-slate-950 dark:text-white font-bold"> Connexion</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <p className="text-white/80">© {new Date().getFullYear()} Colismi.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="fixed bottom-3 end-3">
                <Link to="" className="back-button h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><ArrowLeft className="h-4 w-4"></ArrowLeft></Link>
            </div>


        </>
    )
}

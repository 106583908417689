import logo_light from '../../assets/images/colismi_logo_small.png';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function VerifyEmail() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const { token } = useParams();

    function verifyToken(){
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          };
        
        fetch(backend_url+'/user/verify/'+token, requestOptions)
        .then(response => {
            if (response.ok){
                toast.success("Votre adresse mail a été vérifiée avec succès");
                navigate("/");
            }else{
              toast.error("Impossible de vérifier votre adresse mail");
            }
            return response.json();
        })
        .catch(error => {
         console.log(error);
        });
    }

    useEffect(()=>{
        if(token == undefined){
            navigate("/");
        }else{
            verifyToken();
        }
    }, []);
    return (
        <div>  
            <ToastContainer/>
            <body className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
                <section className="position-relative bg-[url('../../assets/images/bg/bg-6.jpg')] bg-center bg-no-repeat" style={{backgroundSize:'cover'}}>
                    <div className="absolute inset-0 bg-slate-950/50"></div>
                    <div className="container-fluid relative">
                        <div className="grid grid-cols-1">
                            <div className="lg:col-span-4">
                                <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">

                                    <div className="text-center mx-auto">
                                        <Link to="/index"><img src={logo_light} alt="" /></Link>
                                    </div>
                                    <div className="text-center my-auto">
                                        <div className="w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-gray-800">
                                            <div className="grid grid-cols-1">
                                                <h5 className="mb-8 text-xl dark:text-white font-medium">Vérification de votre adresse mail</h5>
                                                <div className="text-start">
                                                    <div className="grid grid-cols-1">
                                                        

                                                        

                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <p className="text-white/80">© {new Date().getFullYear()} Colismi.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="fixed bottom-3 end-3">
                    <Link to="" className="back-button h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><ArrowLeft className="h-4 w-4"></ArrowLeft></Link>
                </div>

            </body>
        </div>
    )
}

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

import styles from './demande.css';
import {React, useState} from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import reponses from '../../../assets/images/colismi/reponses.svg';
import moment from 'moment-timezone';
import date from '../../../assets/images/colismi/trajet/date.svg';
import Moment from 'react-moment';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import depart from '../../../assets/images/colismi/trajet/depart_couleur.svg'
import arrivee from '../../../assets/images/colismi/trajet/arrivee_couleur.svg'
import poids from '../../../assets/images/colismi/trajet/poids.svg'
import fleche from '../../../assets/images/colismi/trajet/fleche.svg';

import description from '../../../assets/images/colismi/trajet/description.svg';

import 'moment/locale/fr';
moment.locale('fr');

export default function Demande({demande, func_supprimer, func_voir, toast}){
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const [checked, setChecked] = useState(demande.visibilite_public);
     // Format date with Moment.js
     let formattedDate = "";
     try {
         formattedDate = moment.utc(demande.date_ajout).fromNow();
     } catch (error) {
         console.error("Error parsing date:", error);
         formattedDate = "Invalid Date";
     }
    
     function statutAffichage(event){

        let token = localStorage.getItem("token");
          demande.visibilite_public = event.target.checked;
          setChecked(event.target.checked);
          //setTrajet(trajet);
          const requestOptions = {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ token
          },
          body: JSON.stringify({
            ...demande
          })
          };
          fetch(backend_url+'/colis/visibility', requestOptions)
          .then(response => {
            if (response.ok){
              toast.success("Demande mise à jour avec succès");
            }
              return response.json();
          })
          .catch(error => {
            console.log(error)
          });
      }

    return (
        <div className={styles}>
            <div className="demande-card">
            <div className="demande-header">
                <div className="demande-location-container">
                <div className="demande-location">
                    <img loading="lazy" src={depart} className="location-icon" alt="Location icon" />
                    <div className="demande-location-name">{demande.ville_depart}</div>
                </div>
                <img loading="lazy" src={fleche} className="arrow-icon" alt="Arrow icon" />
                <div className="demande-location">
                    <img loading="lazy" src={arrivee} className="location-icon" alt="Location icon" />
                    <div className="demande-location-name">{demande.ville_arrivee}</div>
                </div>
                </div>
            </div>
            <div className="demande-info-container">
                <div>
                    <div className="demande-weight-info">
                        <img loading="lazy" src={poids} className="location-icon" alt="Weight icon" />
                        <div className="weight-text">Poids: {demande.kilos}kg</div>
                    </div>
                    <div className="demande-weight-info">
                        <img loading="lazy" src={date} className="location-icon" alt="Weight icon" />
                        <div className="weight-text">Expiration:  <Moment locale="fr" date={demande.date_expiration} fromNow /></div>
                    </div>
                    <div className="demande-weight-info">
                        <SendOutlinedIcon className="location-icon" alt="Weight icon" />
                        <div className="weight-text">Réponses:  {demande.nb_reponses}</div>
                    </div>
                    <div className="demande-weight-info">
                    <FormControlLabel control={<Switch  checked={checked} color="secondary"  onChange={statutAffichage} />} label="Afficher ma demande dans les recherches"  />
                    </div>
                </div>
                <div className="demande-description-container">
                    <img loading="lazy" src={description} className="quote-icon" alt="Quote icon" />
                    <div className="demande-description-text">
                    "{demande.description}"
                    </div>
                </div>
            </div>
            <div className="demande-footer">
                <div className="divider"></div>
                <div className="demande-footer-content">
                <div className="timestamp">
                    <img loading="lazy" src={date} className="clock-icon" alt="Clock icon" />
                    <div className="timestamp-text">{formattedDate}</div>
                </div>
                <div className="demande-action-buttons">
                    <div className="delete-button" tabIndex="0" role="button" onClick={func_supprimer}>
                    <DeleteOutlinedIcon/>
                    <div className="delete-text">Suprimer</div>
                    </div>
                    { demande.reponses.length >0 &&
                    <div className="demande-view-responses-button" tabIndex="0" role="button" onClick={func_voir}>
                        <img loading="lazy" src={reponses} className="view-responses-icon" alt="View responses icon" />
                         <div className="view-responses-text">Voir les réponses</div>
                    </div>
                    }
                </div>
                </div>
            </div>
            </div>
            </div>

    );
}
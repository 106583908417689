import contact from '../assets/images/contact.svg';
import React from 'react'
import Navbar from '../component/navbar'
import ContactData from '../component/contact-data';
import Footer from '../component/footer';
import { ToastContainer, toast } from 'react-toastify';

export default function ContactUs() {
    const backend_url =process.env.REACT_APP_BACKEND_URL;
    var validator = require('validator');

    function envoiMessaage(){
        const nom = document.getElementById('name').value;
        const commentaire = document.getElementById('comments').value;
        const email = document.getElementById('email').value;
        const sujet = document.getElementById('subject').value;

        if (!validator.isEmail(email)){
            toast.error("L'adresse mail renseignée n'est pas valide");
        return
       }

        if (nom === "" || commentaire === "" || email === "" || sujet === ""  ){
            return
        }
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            nom: nom,
            commentaire: commentaire,
            email: email,
            sujet: sujet
          
          })};
        
        fetch(backend_url+'/contact/add', requestOptions)
        .then(response => {
            if(response.ok){
                document.getElementById('name').value = "";
                document.getElementById('comments').value = "";
                document.getElementById('email').value = "";
                document.getElementById('subject').value = "";
            }
            return response.json();
        })
        .then( () => {
            toast.success("Votre message a bien été envoyé!");
        })
    }
    return (
        <>
            <Navbar />
            <ToastContainer />
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Contactez nous</h3>
                    </div>
                </div>
            </section>
            <section className="relative">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium titre-about">Des avis, des remarques ? contactez nous !</h3>

                                    <form name="myForm" id="myForm">
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="name" className="form-label font-medium">Votre nom:</label>
                                                <input name="name" id="name" type="text" className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="form-label font-medium">Email:</label>
                                                <input name="email" id="email" type="email" className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="subject" className="form-label font-medium">Sujet:</label>
                                                <input name="subject" id="subject" className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>

                                            <div className="mb-5">
                                                <label htmlFor="comments" className="form-label font-medium">Commentaire:</label>
                                                <textarea name="comments" id="comments" className="input-wrapper w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2 textarea h-28" placeholder=""></textarea>
                                            </div>
                                        </div>
                                    </form>
                                    <button onClick={envoiMessaage} id="submit" name="send" className="w-full colismi-button rounded-md">Envoyer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactData />
            </section>
            <div className="container-fluid relative">
                <div className="grid grid-cols-1 hidden">
                    <div className="w-full leading-[0] border-0">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                            style={{ border: 0 }} className="w-full h-[500px]" title="contactUs" allowFullScreen> </iframe>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
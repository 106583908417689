const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

// Function to send metrics to Pushgateway
// function sendToPushgateway(metric) {
function sendToAnalytics(metric) {
  const jobName = 'web_vitals'; // Your job name
  const instance = 'colismi_frontend'; // You can customize instance names if needed

  // Prometheus metric format
  const body = `
    # TYPE ${metric.name} gauge
    ${metric.name}{job="${jobName}",instance="${instance}"} ${metric.value}
  `;

  const url = 'https://pushgateway.colismi.com/metrics/job/web_vitals/instance/colismi_frontend';

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: 'POST', headers: { 'Content-Type': 'text/plain' }, keepalive: true });
  }
}

export { reportWebVitals, sendToAnalytics };

// export default reportWebVitals;

/* eslint-disable react/no-unescaped-entities */
import  styles from "./request-item.css";
import React from 'react';
import moment from 'moment-timezone';
import CategoryIcon from '@mui/icons-material/Category';
import profil from '../../../assets/images/colismi/trajet/user.svg';
import fleche from '../../../assets/images/colismi/trajet/fleche.svg';
import depart from '../../../assets/images/colismi/trajet/depart_couleur.svg';
import arrivee from '../../../assets/images/colismi/trajet/arrivee_couleur.svg';
import date from '../../../assets/images/colismi/trajet/date.svg';
import description from '../../../assets/images/colismi/trajet/description.svg';
import charge from '../../../assets/images/colismi/trajet/charge.svg';
import etoile from '../../../assets/images/colismi/trajet/etoile.svg';
import DoneIcon from '@mui/icons-material/Done';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
moment.locale('fr');

export default function RequestItemV2(data){
    const options_type_demande = {
         'Information':  "Demande d'information" ,
         'Achat': "Achat d'articles" ,
         'Envoi': 'Envoi de colis' ,
         'Coursier': 'Je recherche un coursier' 
    }

    var id = localStorage.getItem("id");
    const repondu = localStorage.getItem("req/"+id+""+data.id);
    
    function handleItemChoice(item){
        data.onClick(item);
    }

    // Format date with Moment.js
    let formattedDate = "";
    try {
        formattedDate = moment.utc(data.date_ajout).fromNow();
    } catch (error) {
        console.error("Error parsing date:", error);
        formattedDate = "Invalid Date";
    }

    return(
        <div className={styles}>
            <div className="profile-card-request">
            <div className="profile-header-request">
                <div className="profile-info-request">
                <div className="user-avatar-request">
                    <img src={profil} alt="User avatar" className="avatar-image" />
                    <div className="user-details-request">
                    <div className="user-name-request"> {data.colis.pseudo}</div>
                    <div className="user-rating">
                        <div className="rating-value hidden">4.7</div>
                        <img src={etoile} alt="Rating star" className="rating-star hidden" />
                        <div className="rating-count hidden">(14)</div>
                    </div>
                    </div>
                </div>
                <div className="location-info-request">
                    <div className="location-item-request">
                    <img src={depart} alt="Province icon" className="location-icon" />
                    <div className="location-name-request">{data.ville_depart}</div>
                    </div>
                    <img src={fleche} alt="Divider" className="divider-icon" />
                    <div className="location-item-request">
                    <img src={arrivee} alt="City icon" className="location-icon" />
                    <div className="location-name-request">{data.ville_arrivee}</div>
                    </div>
                </div>
                </div>
                <div className="divider"></div>
            </div>
            <div className="content-section-request">
                <div className="content-columns-request">
                <div className="details-column-request">
                    <div className="details-list-request">
                        <div className="detail-item-request">
                            <img src={charge} alt="Weight icon" className="detail-icon" />
                            <div className="detail-text">Charge: {data.kilos}kg</div>
                        </div>
                    
                        <div className="detail-item-request">
                            <CategoryIcon sx={{ fontSize: 20, color: '#5f2972' }} />
                            <div className="detail-text">Type: {options_type_demande[data.objet]}</div>
                        </div>
                        { (data.nb_reponses === 1  || data.nb_reponses === 0) &&
                        <div className="demande-weight-info">
                            <SendOutlinedIcon className="location-icon" alt="Weight icon" />
                            <div className="weight-text"> {data.nb_reponses} Réponse </div>
                        </div>
                        }
                        { data.nb_reponses > 1 &&
                        <div className="demande-weight-info">
                            <SendOutlinedIcon className="location-icon" alt="Weight icon" />
                            <div className="weight-text"> {data.nb_reponses} Réponses</div>
                        </div>
                        }
                        {repondu &&
                        <div className="detail-item-request">
                             <DoneIcon sx={{ fontSize: 20, color: 'green' }} />
                             <div className="detail-text">Vous avez déjà repondu à cette demande</div>
                        </div>
                        }
                    </div>
                </div>
                <div className="description-column-request">
                    <div className="description-content-request">
                    <img src={description} alt="Quote icon" className="description-icon" />
                    <div className="description-text-request">
                        "{data.description}"
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="footer-section">
                <div className="divider"></div>
                <div className="footer-content">
                <div className="availability-button" onClick={() => handleItemChoice(data)}>Répondre</div>
                <div className="last-seen">
                    <img src={date} alt="Clock icon" className="last-seen-icon" />
                    <div className="last-seen-text">{formattedDate}</div>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}
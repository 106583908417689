/* eslint-disable react/no-unescaped-entities */
import  styles from "./detail-trajet.css"
import React, { useEffect, useState } from 'react'
import NavbarV2 from '../../../component/navbar-v2'
import Footer from '../../../component/footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'react-moment';
import 'moment/locale/fr';
import { useLocation, } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Boat from '@mui/icons-material/DirectionsBoat';
import FlightTakeoff from '@mui/icons-material/FlightTakeoff';
import { Link } from "react-router-dom";

import depart from '../../../assets/images/colismi/trajet/depart.svg'
import arrivee from '../../../assets/images/colismi/trajet/arrivee.svg'

import prix from '../../../assets/images/colismi/trajet/prix.svg'
import poids from '../../../assets/images/colismi/trajet/poids.svg'
import date from '../../../assets/images/colismi/trajet/date.svg'

import profil from '../../../assets/images/colismi/trajet/user.svg';

import facebook from '../../../assets/images/colismi/reseaux/facebook.svg';
import twitter from '../../../assets/images/colismi/reseaux/twitter.svg';
import whatsapp from '../../../assets/images/colismi/reseaux/whatsapp.svg';
import FormReservation from "../../v2/form-reservation/form-reservation";
import moment from 'moment-timezone';
moment.locale('fr');

export default function DetailTravel() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const {state} = useLocation();
    const navigate = useNavigate();
    const { numero = undefined } = useParams();

    const [trajet, setTrajet] = useState(undefined);
    const [isLoggedin, setIsLoggedin] = useState(false);    

    useEffect(() => {

          if (state?.numero  === undefined && numero === undefined){
            // redirect to search page
            navigate('/travel-search');
        }
        function getTrajet(numero){  
            if(numero === undefined && state?.numero === undefined){
              return;
            }
            const requestOptions = {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' },
              
             };
            
            fetch(backend_url+'/trajets/get_unique?numero='+numero, requestOptions)
            .then(response => {
                console.log(response);
                if (!response.ok) {
                    throw new Error('Network error');
                  }
                return response.json();
            })
            .then(data => {
                setTrajet(data);
                //toast.success("Annonce ajoutée avec succès!");
            })
            .catch(error => {
                console.log("error")
                toast.error("Impossible de recupérer l'annonce veuillez réessayer plus tard");
                console.log(error);
            });
          }

        if (numero !== undefined){
           getTrajet(numero);
        }
        if (state?.numero  !== undefined){
            getTrajet(state.numero);
        }
        

        let token = localStorage.getItem("token");
        if(token == undefined){
          setIsLoggedin(false)
        }else{
          setIsLoggedin(true)
        }

       
    
        
      }, []);

      

      function sendReservation(){
        let token = localStorage.getItem("token");

        const commentaire = document.getElementById('description').value;  
        const kilos = document.getElementById('poids').value;
        const longueur = document.getElementById('longueur').value;
        const largeur = document.getElementById('largeur').value;
        const hauteur = document.getElementById('hauteur').value;
  
    
        if (commentaire == undefined || commentaire == ""){
            toast.error("Le commentaire ne doit pas être vide");
            return
        }
        if (kilos == undefined || kilos == ""){
            toast.error("Le nombre de kilos ne doit pas être vide");
            return
        }
        if (longueur == undefined || longueur == ""){
          toast.error("La longueur ne doit pas être vide");
          return
        }
        if (largeur == undefined || largeur == ""){
          toast.error("La largeur ne doit pas être vide");
          return
        }
        if (hauteur == undefined || hauteur == ""){
          toast.error("La hauteur ne doit pas être vide");
          return
        }
        const requestOptions = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
        
        },
          body: JSON.stringify({ 
            email: "",
            telephone: "",
            commentaire: commentaire,
            kilos: kilos,
            ville_depart: trajet.ville_depart ,
            ville_arrivee: trajet.ville_arrivee,
            numero_trajet: trajet.numero,
            tarif_kilos: 0
          })};
        
        fetch(backend_url+'/reservation/add', requestOptions)
        .then(response => {

            if (response.ok) {
                toast.success("Votre demande a bien eté envoyée!");
              }
            return response.json();
        })
        .then(data => {
          if(data.detail != undefined){
            toast.error(data.detail);
          }
        })
      }

      function getFormattedDate(date_ajout){
        // Format date with Moment.js
        let formattedDate = "";
        try {
            formattedDate = moment.utc(date_ajout).fromNow();
        } catch (error) {
            console.error("Error parsing date:", error);
            formattedDate = "Invalid Date";
        }
        return formattedDate;
    }

    

    return (
        <div className={styles}>
            <NavbarV2 />
            <ToastContainer />
            <section className="top-display py-3 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"  style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 bg-slate-950/80 navbar-background"></div>

                <div className="container">
                    <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                          <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Détails du trajet</h3>
                          <h1 className='text-white'>Numéro du trajet: {trajet && trajet.numero}</h1>

                    </div>
                   
                </div>
                
                </div>
                { trajet &&
                <div className="event-card container">
                    <div className="event-details">
                      <h2 className="event-title">{trajet.ville_depart}</h2>
                      <div className="event-date-wrapper">
                        <img loading="lazy" src={depart} className="icon" alt="" />
                        <span><Moment format="LL" >{trajet.date_depart}</Moment></span>
                      </div>
                    </div>
                    {trajet.type === "Avion"  &&
                            <FlightTakeoff className='colismi-icon' sx={{ fontSize: 80, color: '#ffff' }} />

                            }
                            {trajet.type === "Fret Aerien"  &&
                            <FlightTakeoff className='colismi-icon' sx={{ fontSize: 80, color: '#ffff' }} />

                            }
                            {trajet.type === "Bateau"  &&
                            <Boat className='colismi-icon' sx={{ fontSize: 80, color: '#ffff' }} />

                            }

                    <div className="event-details">
                      <h2 className="event-title">{trajet.ville_arrivee}</h2>
                      <div className="event-date-wrapper">
                        <img loading="lazy" src={arrivee} className="icon" alt="" />
                        <span><Moment format="LL" >{trajet.date_arrivee}</Moment></span>
                      </div>
                    </div>
                </div>
                 }
            </section>
            <section className="main-section">
                <div className="details-top">
                { trajet &&
                    <div className="travel-details">
                        <div className="publication-card">
                          <img loading="lazy" src={date} className="user-avatar" alt="User avatar"  style={{ margin: 'auto' }}/>
                          <div className="publication-info">
                            <div className="user-name-time">{getFormattedDate(trajet.date_ajout)}</div>
                            <div className="publication-type">Publication</div>
                          </div>
                        </div>
                        <div className="publication-card">
                          <img loading="lazy" src={poids} className="user-avatar" alt="User avatar"  style={{ margin: 'auto' }}/>
                          <div className="publication-info">
                            { trajet.capacite > 0 &&
                              <div className="user-name-time">{trajet.capacite}Kg</div>
                            }
                            { trajet.capacite < 0  &&
                              <div className="user-name-time">Non renseignée</div>
                            }
                            
                            <div className="publication-type">Capacité</div>
                          </div>
                        </div>
                        <div className="publication-card">
                          <img loading="lazy" src={prix} className="user-avatar" alt="User avatar"  style={{ margin: 'auto' }}/>
                          <div className="publication-info">
                            { trajet.prix < 0 &&
                              <div className="user-name-time">Non renseigné</div>
                            }
                            {trajet.prix >0 &&
                              <div className="user-name-time">{trajet.prix}€/Kg</div>
                            }
                            
                            <div className="publication-type">Prix</div>
                          </div>
                        </div>

                        <div className="publication-card">
                           {trajet.type === "Avion"  &&
                            <FlightTakeoff className='user-avatar'  sx={{ fontSize: 40, color: '#5f2972', margin: 'auto' }} />

                            }
                             {trajet.type === "Fret Aerien"  &&
                            <FlightTakeoff className='user-avatar' sx={{ fontSize: 40, color: '#5f2972',  margin: 'auto' }} />

                            }
                             {trajet.type === "Bateau"  &&
                            <Boat className='user-avatar' sx={{ fontSize: 40, color: '#5f2972',  margin: 'auto' }} />

                            }
                          <div className="publication-info">
                            <div className="user-name-time">{trajet.type}</div>
                            <div className="publication-type">Transport</div>
                          </div>
                        </div>
                    </div>
                    }
                </div>
                {trajet &&
                <div className="details-trajet">

                  <div className="details-trajet-user">
                      <div className="profile-card">
                        <div className="profile-details">
                          <img loading="lazy" src={profil} className="profile-avatar" alt="Didi's profile picture" />
                          <h2 className="profile-name"><Link to="/traveler"  state={{ user: trajet.user }}> {trajet.user.pseudo}</Link></h2>
                          <p className="profile-role">{trajet.user.type}</p>
                          <div className="profile-divider"></div>
                          <h3 className="profile-description-title">Description</h3>

                              {
                              trajet.user.description &&
                                      <p className="preserve-whitespace profile-description">{trajet.user.description}</p>
                              }

                              {
                              trajet.user.description === "" &&
                                      <p>Aucune description</p>
                              }
                          <div className="profile-divider"></div>
                          <ul className="profile-info-list">
                              <li className="profile-info-item">
                              <img loading="lazy" src={facebook} className="profile-info-icon" alt="Info icon" />
                                  
                                      {( trajet.user.facebook === "" || trajet.user.facebook === null ) &&
                                        <span className="profile-info-text">Non renseigné</span>
                                      }
                                      {trajet.user.facebook !=="" &&
                                        <span className="profile-info-text">{trajet.user.facebook}</span>
                                      }
                                  
                              </li>
                              <li className="profile-info-item">
                              <img loading="lazy" src={twitter} className="profile-info-icon" alt="Info icon" />
                                  
                                      {( trajet.user.twitter === "" || trajet.user.twitter === null ) &&
                                        <span className="profile-info-text">Non renseigné</span>
                                      }
                                      {trajet.user.twitter !=="" &&
                                        <span className="profile-info-text">{trajet.user.twitter}</span>
                                      }
                                  
                              </li>
                              <li className="profile-info-item hidden">
                              <img loading="lazy" src={whatsapp} className="profile-info-icon" alt="Info icon" />
                                  
                                      {( trajet.user.telephone === "" || trajet.user.telephone === null ) &&
                                        <span className="profile-info-text">Non renseigné</span>
                                      }
                                      {trajet.user.telephone !=="" &&
                                        <span className="profile-info-text">{trajet.user.telephone}</span>
                                      }
                              </li>
                          </ul>
                        </div>
                        <button className='bouton-voir'> <Link to="/traveler"  state={{ user: trajet.user }}>Voir le profil</Link></button>
                  </div>

                    
                  </div>
                  <div className="details-trajet-post">
                    <FormReservation
                      trajet={trajet}
                    />
                  
                  </div>

                </div>
                }
                { trajet &&
                <div className='travel-commentaire hidden'>
                    <div>
                        <strong>Description</strong>
                    </div>
                    
                    <br/>
                    <div className='preserve-whitespace'>
                        <p>{trajet.description}
                        </p>
                    </div>
                   <br/>
                   
                </div>
                }
                { isLoggedin && 
                <div className="client-input hidden">
                    <div>
                        <h1>Contactez le voyageur</h1>
                        <p>Vous avez un colis à envoyer, laisser les détails de votre colis au voyageur pour qu'il vous recontacte </p>

                    </div>
                    <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="poids" className="form-label font-medium">Poids du colis:</label>
                                                <input  required name="poids" id="poids" type='number' className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" />
                    </div>
                  
                    <div className="mb-5">
                        <label htmlFor="description" className="form-label font-medium">Description du colis:</label>
                        <textarea required name="description" id="description" className="form-input w-full py-2 px-3 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2 textarea h-28" placeholder=""></textarea>
                    </div>
                    <button id="submit" name="send" onClick={sendReservation} className="colismi-button-cm">Envoyer une demande</button>
                </div>
                }
                { !isLoggedin &&

                  <div className="client-input hidden">
                    <p>Connectez vous pour contacter le voyageur</p>

                    <button className='colismi-button-cm'><Link to='/signup'> Créer mon compte/Login </Link> </button>
                  </div>
                  

                }
                <div className="container">
                </div>
            </section>
            <Footer />
        </div>
    )
}
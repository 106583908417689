import styles from "./user-profil.css";
import no_result from '../../../assets/images/colismi/Illustration-erreur.svg'
import React, { useEffect, useState } from 'react';
import NavbarV2 from "../../../component/navbar-v2.js";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import TravelItemV2 from '../travel/travel-item';

import facebook from '../../../assets/images/colismi/reseaux/facebook.svg';
import twitter from '../../../assets/images/colismi/reseaux/twitter.svg';
import whatsapp from '../../../assets/images/colismi/reseaux/whatsapp.svg';
import profil from '../../../assets/images/colismi/trajet/user.svg';
import Footer from "../../../component/footer.js";
import ModalReservation from '../../v2/modal-reservation/modal-reservation.js';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import { Link } from "react-router-dom";


export default function UserProfilV2(){
    const backend_url  = process.env.REACT_APP_BACKEND_URL;
    const {state} = useLocation();
    
    const navigate = useNavigate();
    const user = state.user;
    const [reviews, setReviews] = useState([]);
    const [meanRating, setMeanRating] = useState(0); // State to hold mean rating
    const [selectedTrajet, setSelectedTrajet] = useState(undefined);
    const [open, setOpen] = React.useState(false);
    const [trajets, setTrajets] = useState([]);

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickOpen = (data) => {
      setSelectedTrajet(data);
      setOpen(true);
    };

    const linkStyle = {
      textDecoration: "underline",
      color: 'blue'
    };
  
    useEffect(() => {


      if (user  === undefined){
        // redirect to search page
        navigate('/travel-search');
        }

        function getUserTrajets(){  
            const requestOptions = {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' },
              
             };
            
            fetch(backend_url+'/trajets/user?id='+user.id, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network error');
                  }
                  
                return response.json();
            })
            .then(data => { 
              setTrajets(data);
                
                //toast.success("Annonce ajoutée avec succès!");
            })
            .catch(error => {
               console.log(error);
                toast.error("Impossible de recupérer la liste des trajets veuillez réessayer plus tard");
            });
          }

          function fetchReviews() {
              fetch(backend_url + '/reviews/user?id=' + user.id)
              .then(response => {
                  if (!response.ok) {
                      throw new Error('Network error');
                    }
                    
                  return response.json();
              })
              .then(data => { 
                setReviews(data);
                calculateMeanRating(data); // Pass data directly to avoid stale state issues                  
              })
              .catch(error => {
                  console.log(error);
                  toast.error("Impossible de recupérer la liste des reviews veuillez réessayer plus tard");
              });              
        }

          if (user !== undefined){
              getUserTrajets();
              fetchReviews();
          }
              
      }, []);

      const calculateMeanRating = (reviews) => {
        if (reviews.length > 0) {
            const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
            const mean = totalRating / reviews.length;
            setMeanRating(mean);
        } else {
            setMeanRating(0);
        }
      };

        return (
        <div className={styles}>

        <div className="page-wrapper">
        <div className="content-container">
        <NavbarV2 />
        <ToastContainer />
        <section className="top-display py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                        <div className="absolute inset-0 bg-slate-950/80 navbar-background"></div>

                        <div className="container">
                            <div className="container">
                            <div className="grid grid-cols-1 pb-8 text-center mt-10">
                            <h6 className="font-medium leading-normal text-3xl mt-5 text-white">Besoin de transporter un colis?</h6>
                            <h1 className="font-medium leading-normal text-sm mt-1 text-white">Recherchez parmi les annonces disponible.</h1>
                            
                        </div>
                        
                        </div>
                        </div>
                    </section>

            <main className="main-content">
            <div className="content-columns">
                <aside className="profile-column">
                <div className="profile-card">
                    <div className="profile-details">
                    <img loading="lazy" src={profil} className="profile-avatar" alt="Didi's profile picture" />
                    <h2 className="profile-name">{user.pseudo}</h2>
                    <p className="profile-role">{user.type}</p>
                    <div className="profile-divider"></div>
                    <h3>Description</h3>

                        {
                        user.description &&
                                <p className="preserve-whitespace profile-description">{user.description}</p>
                        }

                        {
                        (user.description === ""  || user.description === null  )&&
                                <p>Aucune description</p>
                        }
                    <div className="profile-divider"></div>
                    <ul className="profile-info-list">
                        <li className="profile-info-item">
                        <img loading="lazy" src={facebook} className="profile-info-icon" alt="Info icon" />
                            
                                {( user.facebook === "" || user.facebook === null ) &&
                                   <span className="profile-info-text">Non renseigné</span>
                                }
                                {user.facebook !=="" &&
                                  <span className="profile-info-text">{user.facebook}</span>
                                }
                            
                        </li>
                        <li className="profile-info-item">
                        <img loading="lazy" src={twitter} className="profile-info-icon" alt="Info icon" />
                             
                                {( user.twitter === "" || user.twitter === null ) &&
                                   <span className="profile-info-text">Non renseigné</span>
                                }
                                {user.twitter !=="" &&
                                   <span className="profile-info-text">{user.twitter}</span>
                                }
                            
                        </li>
                        <li className="profile-info-item hidden">
                        <img loading="lazy" src={whatsapp} className="profile-info-icon" alt="Info icon" />
                            
                                {( user.telephone === "" || user.telephone === null ) &&
                                   <span className="profile-info-text">Non renseigné</span>
                                }
                                {user.telephone !=="" &&
                                   <span className="profile-info-text">{user.telephone}</span>
                                }
                        </li>
                        <li className="profile-info-item">
                        <EmailOutlinedIcon className="profile-info-icon" alt="Info icon" />
                                {( user.is_verified === false || user.is_verified === undefined ) &&
                                   <span className="profile-info-text">Adresse mail non vérifiée</span>
                                }
                                {user.is_verified === true &&
                                   <span className="profile-info-text">Adresse mail vérifiée</span>
                                }
                        </li>
                    </ul>
                    <div className="profile-divider"></div>


                    <div className="mt-2">
                      <ul className="list-none mb-0 text-amber-400 flex justify-center">
                          {[...Array(Math.round(meanRating))].map((_, i) => (
                              <li key={i} className="inline"><i className="mdi mdi-star"></i></li>
                          ))}
                          {[...Array(5 - Math.round(meanRating))].map((_, i) => (
                              <li key={i} className="inline"><i className="mdi mdi-star-outline"></i></li>
                          ))}
                      </ul>
                      <span className="text-lg ml-2">Note: {meanRating.toFixed(1)} </span>
                      <span className="mx-2">|</span> {/* Separator */}
                      <Link to="/user-review" state={{ user_reviews: reviews, user_mean_rating: meanRating, user_pseudo: user.pseudo }} style={linkStyle}>
                        <span className="text-blue-500 underline text-lg ml-2"> {reviews.length} Avis </span>
                      </Link>
                      
                    </div>

                  </div>
                </div>
                </aside>
                <section className="trips-column">
                <div className="trips-container">
                    <h2 className="trips-title">Mes Trajets</h2>
                    <div className="trips-list">
                    {trajets.length === 0 &&
                            <div>
                                <p style={{textAlign: 'center'}}>Aucun trajet à afficher</p>
                                <img src={no_result} className='image-no-result'/>
                            </div>
                          }
                          {
                                    trajets.map((item, index) =>(
                                                        <TravelItemV2
                                                          key={index}
                                                          {...item}
                                                          display_header={false}
                                                          display_interest={false}
                                                          onClick={() => handleClickOpen(item)}
                                                      
                                                        />
                                                    ))
                            }
                    </div>
                </div>
                </section>
            </div>
            </main>
        </div>
        <Footer/>
        </div>
        <ModalReservation
         open={open}
         func_close={handleClose}
         trajet={selectedTrajet}
        />
        </div>
        )
}
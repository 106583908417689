import React, { useState } from 'react';
import PropTypes from 'prop-types';

const StarRating = ({ name, value, onChange }) => {
    const [rating, setRating] = useState(value);

    const handleClick = (ratingValue) => {
        setRating(ratingValue);
        onChange({ target: { name, value: ratingValue } });
    };

    return (
        <div className="star-rating">
            {[...Array(5)].map((_, i) => {
                const ratingValue = i + 1;
                return (
                    <label key={ratingValue} className="cursor-pointer">
                        <input
                            type="radio"
                            name={name}
                            value={ratingValue}
                            onChange={() => handleClick(ratingValue)}
                            style={{ display: 'none' }}
                        />
                        <i
                            className={`mdi ${ratingValue <= rating ? 'mdi-star' : 'mdi-star-outline'} text-amber-400 text-2xl`}
                            onClick={() => handleClick(ratingValue)}
                        ></i>
                    </label>
                );
            })}
        </div>
    );
};

StarRating.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default StarRating;

/* eslint-disable react/prop-types */
import React from 'react';
import styles from './mon-trajet.css';
import FlightTakeoff from '@mui/icons-material/FlightTakeoff';
import Boat from '@mui/icons-material/DirectionsBoat';
import depart from '../../../assets/images/colismi/trajet/depart_couleur.svg';
import arrivee from '../../../assets/images/colismi/trajet/arrivee_couleur.svg';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined';

import prix from '../../../assets/images/colismi/trajet/prix.svg';
import poids from '../../../assets/images/colismi/trajet/poids.svg';


export default function MonTrajet({trajet, func_annuler, func_gerer}){

    return (
        <div className={styles}>
        <div className="shipment-card">
        <div className="shipment-header">
            <div className="shipment-details">
            <div className="location-info">
                <div className="location-wrapper">
                     <img src={depart} alt="Departure location icon" className="location-icon" />
                     <div className="location-name">{trajet.ville_depart}</div>
                </div>
                <div className="date">{trajet.date_depart}</div>
            </div>
            {trajet.type === "Avion"  &&
                                                        <FlightTakeoff className='colismi-icon' sx={{ fontSize: 40, color: '#5f2972' }} />

                                                    }
                                                    {trajet.type === "Fret Aerien"  &&
                                                        <FlightTakeoff className='colismi-icon' sx={{ fontSize: 40, color: '#5f2972' }} />

                                                    }
                                                    {trajet.type === "Bateau"  &&
                                                        <Boat className='colismi-icon' sx={{ fontSize: 40, color: '#5f2972' }} />

                                                    }
            <div className="location-info">
                <div className="location-wrapper">
                <img src={arrivee} alt="Arrival location icon" className="location-icon" />
                <div className="location-name">{trajet.ville_arrivee}</div>
                </div>
                <div className="date">{trajet.date_arrivee}</div>
            </div>

            {trajet.statut === "ACTIF"  &&
                    <div className="status-badge-actif">Actif</div>

            }
            {trajet.statut !== "ACTIF"  &&
                   <div className="status-badge-annuler">{trajet.statut}</div>

            }
            
            </div>
        </div>
        <div className="shipment-info">
            <div className="info-list">
                <div className="info-item">
                    <img src={poids} alt="Capacity icon" className="info-icon" />
                    <div className="info-text">Capacite: {trajet.capacite}kg</div>
                </div>
                <div className="info-item">
                {trajet.type === "Avion"  &&
                                                            <FlightTakeoff className='colismi-icon' sx={{ fontSize: 20, color: '#5f2972' }} />

                                                        }
                                                        {trajet.type === "Fret Aerien"  &&
                                                            <FlightTakeoff className='colismi-icon' sx={{ fontSize: 20, color: '#5f2972' }} />

                                                        }
                                                        {trajet.type === "Bateau"  &&
                                                            <Boat className='colismi-icon' sx={{ fontSize: 20, color: '#5f2972' }} />

                                                        }
                    <div className="info-text">Transport en {trajet.type}</div>
                </div>
                <div className="info-item">
                    <img src={prix} alt="Price icon" className="info-icon" />
                    <div className="info-text">Prix: {trajet.prix}€/Kg</div>
                </div>
                <div className="info-item">
                    <BookOnlineOutlinedIcon className='colismi-icon' sx={{ fontSize: 30, color: '#5f2972' }} />
                    <div className="info-text">Réservations: {trajet.nb_reservations || 0}</div>
                </div>
            </div>
            <div className="shipment-number">
            <span className="bold-text">Numéro :</span>
            <span>{trajet.numero}</span>
            </div>
        </div>
        <div className="shipment-actions">
            <div className="action-divider"></div>
            <div className="action-buttons">
            <button className="action-button cancel-button" onClick={func_annuler}>
                <DeleteOutlinedIcon />
                <span>Annuler</span>
            </button>
            <button className="action-button manage-button" onClick={func_gerer}>
                <ModeEditOutlineOutlinedIcon/>
                <span>Gérer</span>
            </button>
            </div>
        </div>
        </div>
        </div>

    );
}
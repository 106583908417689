/* eslint-disable react/no-unescaped-entities */
import '../App.css';
import React, { useEffect, useState } from 'react'
import Footer from '../component/footer';
import GetInTouch from '../component/get-in-touch';
import OurServices from '../component/our-services';
import About from '../component/about';
import cta from '../assets/images/intro.png';
import { Link } from "react-router-dom";
import Navbar from '../component/navbar'
import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/css/modal-video.css";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
//import Paper from '@mui/material/Paper';
//import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import FlagIcon from '@mui/icons-material/Flag';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Helmet } from 'react-helmet';


export default function Index() {
    const backend_url =process.env.REACT_APP_BACKEND_URL;
    /*const StyledPaper = styled(Paper)(() => ({
        backgroundColor: '#f0f0f0',
      }));*/
    
    const navigate = useNavigate();
    const [villes, setVilles] = useState([]);
    const [villeArrivee, setVilleArrivee] = useState({nom:"", emoji:""});
    const [villeDepart, setVilleDepart] = useState({nom:"", emoji:""});


    const [isOpen, setOpen] = useState(false)
    function searchTravel(){
        const depart = document.getElementById('input-depart').value;
        const arrivee = document.getElementById('input-arrivee').value;
        console.log(arrivee);
        navigate("/travel-search", {state:{depart: depart, arrivee: arrivee}});
        
        //console.log(document.getElementById('input-arrivee'));
       
        
        
    }
    useEffect(() => {
    
        async function getVilles() {
            try {
              const response = await fetch(backend_url+'/villes');
              const data = await response.json();
              setVilles(data);
            } catch (error) {
              console.error(error);
            }
          }

        getVilles();
      }, []);

   
      function departChanged(event, newValue){
        setVilleDepart(newValue);
    }

    function arriveeChanged(event, newValue){
        setVilleArrivee(newValue);
    }
    

    return (
        <div>
            <Navbar />
            <div>
                <Helmet>
                <title>Bienvenue sur l'application Colismi</title>
                <meta name="description" content="Colismi est une plateforme innovante qui facilite la mise en relation entre particuliers pour le transport de colis." />
                <meta name="keywords" content="Colismi, Transport, Colis" />
                </Helmet>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="BNYMIYFq_Ss" onClose={() => setOpen(false)} />
            <section className="py-36 lg:py-56 w-full table relative bg-[url('../../assets/images/bg/bg.jpg')] bg-top bg-no-repeat"  style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 bg-gradient-to-t to-slate-950/50 via-slate-950/75 from-slate-950"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <Link to="#" onClick={() => setOpen(true)} data-type="youtube" data-id="yba7hPeTSjk" className="lightbox lg:h-20 h-16 lg:w-20 w-16 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white hover:bg-violet-600 text-violet-600 hover:text-white duration-500 ease-in-out mx-auto">
                            <i className="mdi mdi-play inline-flex items-center justify-center text-3xl"></i>
                        </Link>
                        <h3 className="font-medium leading-normal text-4xl mb-5 mt-10 text-white">Trouvez le transporteur idéal pour vos colis</h3>
                        <p className="text-slate-400 text-lg max-w-xl mx-auto mb-2 mt-2">Colismi c'est la plateforme qui simplifie vos envois de colis. Faites votre recherche ici</p>
                
                        <div className="search-zone-home">
                            <Autocomplete
                                    className='autocomplete'
                                    disablePortal
                                    id="input-depart"
                                    value={villeDepart}
                                    options={villes}
                                    onChange={departChanged}
                                    sx={{ minWidth: 300,
                                        [`& .${autocompleteClasses.popupIndicator}`]: {
                                        transform: "none"
                                      } }}
                                    popupIcon={<PlaceOutlinedIcon/>}
                                    getOptionLabel={(option) => option.emoji + " " + option.nom}
                                    renderInput={(params) => <TextField {...params} label="Ville de départ" variant="filled"    style={{ backgroundColor: 'white' }} />}
                                    />
                            <Autocomplete
                                    className='autocomplete'
                                    disablePortal
                                    id="input-arrivee"
                                    value={villeArrivee}
                                    options={villes}
                                    onChange={arriveeChanged}
                                    sx={{ minWidth: 300, 
                                        [`& .${autocompleteClasses.popupIndicator}`]: {
                                        transform: "none"
                                      } }}
                                    popupIcon={<FlagIcon/>}
                                    getOptionLabel={(option) => option.emoji + " " + option.nom}
                                    renderInput={(params) => <TextField {...params}  label="Ville d'arrivée"   variant="filled"   style={{ backgroundColor: 'white' }} />}
                                    />
                            
                            <Button className="colismi-button" onClick={searchTravel}><Link state={{ville_arrivee: villeArrivee, ville_depart: villeDepart}}>Rechercher</Link></Button>;
                            
                        </div>
                    </div>
                </div>
            </section>
            <About />
            <OurServices />
            <section className="relative md:py-24 py-16 md:pt-0 pt-0  dark:bg-slate-800">
                <div className="container">
                    <div className="grid grid-cols-1 justify-center">
                        <div className="relative z-1">
                            <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                <div className="lg:col-start-2 lg:col-span-10">
                                    <div className="relative">
                                        <img src={cta} className="rounded-md shadow-lg w-full" alt="" />
                                        <div className="absolute bottom-2/4 translate-y-2/4 end-0 start-0 text-center">
                                            <Link to="#" onClick={() => setOpen(true)} data-type="youtube" data-id="yba7hPeTSjk" className="lightbox lg:h-20 h-16 lg:w-20 w-16 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white hover:bg-violet-600 text-violet-600 hover:text-white duration-500 ease-in-out mx-auto">
                                                <i className="mdi mdi-play inline-flex items-center justify-center text-3xl"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content md:mt-8">
                                <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                    <div className="lg:col-start-2 lg:col-span-10">
                                        <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                                            <div>
                                                <div className="section-title text-md-start">
                                                
                                                    <h3 className="md:text-2xl text-xl font-medium text-white mt-2">Une application  <br /> qui casse les codes.</h3>
                                                </div>
                                            </div>

                                            <div className="section-title text-md-start">
                                                <p className="text-white/50 max-w-xl mx-auto mb-2">L'envoi de colis n'a jamais été aussi simple. Utiliser colismi c'est maitriser son temps d'envois de colis</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute bottom-0 end-0 start-0 h-4/5 md:h-2/3 colismi-bg"></div>
            </section>
            <GetInTouch />
            <Footer />
        </div>
    )
}

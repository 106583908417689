/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import styles from './commentaire.css';
import {React} from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Comment from '@mui/icons-material/Comment';




import date from '../../../assets/images/colismi/trajet/date.svg'

import profil from '../../../assets/images/colismi/trajet/user.svg';
import description from '../../../assets/images/colismi/trajet/description.svg';

import moment from 'moment-timezone';
moment.locale('fr');



export default function Commentaire({commentaire, btn_create}){
    // Format date with Moment.js
    let formattedDate = "";
    try {
        console.log(commentaire);
        formattedDate = moment.utc(commentaire.date_ajout).fromNow();
    } catch (error) {
        console.error("Error parsing date:", error);
        formattedDate = "Invalid Date";
    }
    return(
        <div className={styles}>
        <div className="commentaire-card">
            <div className="commentaire-header">
                <div className="commentaire-info">
                <div className="com-user-details">
                    <img src={profil} alt="User avatar" className="com-user-avatar" />
                    <div className="com-user-name-rating">
                    <div className="com-user-name"> <Link to="/traveler"  state={{ user: commentaire.user }}> <strong>{commentaire.user.pseudo}</strong></Link></div>
                    <div className="rating-container hidden">
                        <div className="rating-value hidden">4.5</div>
                        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/d2a17639e223d5c69dfbd0af2899b20dfa8ea9fa06dbcb8d5c1d8d920e8bbeed?apiKey=0b65d8b49c0846d0bfbbad44662cd24e&" alt="Rating star" className="com-rating-star" />
                        <div className="rating-count hidden">(6)</div>
                    </div>
                    </div>
                </div>
                <div className="commentaire-date">
                    <img src={date} alt="Date icon" className="date-icon" />
                    <div className="date-text">{formattedDate}</div>
                </div>
                </div>
            </div>
            <div className="commentaire-content">
                <img src={description} alt="Quote icon" className="quote-icon" />
                <div className="commentaire-text">
                "{commentaire.description}"
                </div>
            </div>

            <div id='actions'  style={{marginLeft: '20px', marginBottom: '20px'}} >
                            <ul  style={{display: 'inline'}}>
                                <li><Button onClick={btn_create} sx={{ color: '#5f2972', borderColor: '#5f2972' }} variant="outlined" startIcon={<Comment  sx={{ color: '#5f2972' }}/>}>Discuter</Button></li>
                            </ul>

        </div>  
     </div>
     </div>
    )
}
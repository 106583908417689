import logo_light from '../../assets/images/colismi_logo_small.png';
import React from 'react'
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom'

export default function VerifyCode() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const {state} = useLocation();
    const email = state.email;

    function reset(){
        const code = document.getElementById('LoginCode').value;
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            email: email,
            password: code,
          })};
        
        fetch(backend_url+'/user/codelogin', requestOptions)
        .then(response => {
            if (response.ok){
                toast.success("Login OK");
                navigate("/compte");
            }else{
              toast.error("Aucun utilisateur trouvé");
            }
            return response.json();
        })
        .then(data => {    
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('pseudo', data.pseudo);
        })
        .catch(error => {
         console.log(error);
        });
    }
    return (
        <div>  
            <ToastContainer/>
            <body className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
                <section className="position-relative bg-[url('../../assets/images/bg/bg-6.jpg')] bg-center bg-no-repeat" style={{backgroundSize:'cover'}}>
                    <div className="absolute inset-0 bg-slate-950/50"></div>
                    <div className="container-fluid relative">
                        <div className="grid grid-cols-1">
                            <div className="lg:col-span-4">
                                <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">

                                    <div className="text-center mx-auto">
                                        <Link to="/index"><img src={logo_light} alt="" /></Link>
                                    </div>
                                    <div className="text-center my-auto">
                                        <div className="w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-gray-800">
                                            <div className="grid grid-cols-1">
                                                <h5 className="mb-8 text-xl dark:text-white font-medium">Mot de passe oublié</h5>
                                                <p className="text-slate-400 mb-6">Renseignez le code que vous avez reçu dans votre boite mail.</p>
                                                <div className="text-start">
                                                    <div className="grid grid-cols-1">
                                                        <div className="mb-4">
                                                            <label className="form-label font-medium" htmlFor="LoginEmail">Code:</label>
                                                            <input id="LoginCode" type="text" className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                                        </div>

                                                        <div className="mb-4">
                                                            <button  onClick={reset} className="colismi-button rounded-md w-full">Vérifier</button>
                                                        </div>

                                                        <div className="text-center">
                                                            <span className="text-slate-400 me-2"> </span><Link to="/login" className="text-slate-950 dark:text-white font-bold"> Connexion</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <p className="text-white/80">© {new Date().getFullYear()} Colismi</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="fixed bottom-3 end-3">
                    <Link to="" className="back-button h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><ArrowLeft className="h-4 w-4"></ArrowLeft></Link>
                </div>

            </body>
        </div>
    )
}

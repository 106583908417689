import image from '../assets/images/blog/1.jpg';
import React from 'react'
import { Link } from "react-router-dom";

export default function BlogData() {
    const BlogDatadata = [
        {
            image: image,
            title: "Innover dans le transport c'est possible avec colismi.",
            detail: 'Colismi, la nouvelle application qui casse les codes',
            more: 'Read More',
        }
    
    ]

    return (
        <>
            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">

                    {BlogDatadata.map((item, index) => (

                        <div key={index} className="blog relative rounded-md shadow shadow-slate-200 dark:shadow-gray-800 overflow-hidden">
                            <img src={item.image} alt="" />

                            <div className="content p-6">
                                <Link to="/blog-detail" className="text-lg hover:text-violet-600 dark:text-white dark:hover:text-violet-600 transition duration-500 ease-in-out font-medium h5">{item.title}</Link>
                                <p className="text-slate-400 mt-3">{item.detail}</p>

                                <div className="mt-5">
                                    <Link to="" className="hover:text-violet-600 dark:hover:text-violet-600 after:bg-violet-600 dark:text-white transition duration-500">{item.more}<i className="uil uil-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import styles from './account-edit.css';
import { Link } from "react-router-dom";

import facebook from '../../../assets/images/colismi/reseaux/facebook.svg';
import twitter from '../../../assets/images/colismi/reseaux/twitter.svg';
import linkedin from '../../../assets/images/colismi/reseaux/linkedin.png';

import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';



export default function AccountEdit({user, toast}){
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const [value, setValue] = useState(undefined);

    function majProfil(){
        let token = localStorage.getItem("token");

        const nom = document.getElementById('nom').value;
        const type = document.getElementById('type').value;
        const prenom = document.getElementById('prenom').value;
        const pseudo = document.getElementById('pseudo').value;
        const email = document.getElementById('email').value;
        const linkedin = document.getElementById('linkedin').value;
        const facebook = document.getElementById('facebook').value;
        const twitter = document.getElementById('twitter').value;
        const website = document.getElementById('website').value;
        const telephone = value;//document.getElementById('telephone').value;
        const description = document.getElementById('description-profil').value;
  
        const requestOptions = {
          method: 'POST',
          headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
        },
          body: JSON.stringify({ 
            nom: nom,
            type: type,
            prenom: prenom,
            email: email,
            pseudo: pseudo,
            facebook: facebook,
            linkedin: linkedin,
            twitter: twitter,
            website: website,
            telephone: telephone,
            description: description
          
          })};
        
        fetch(backend_url+'/user/update', requestOptions)
        .then(response => {
            if(response.ok){
                /*** Handle response ok */
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            toast.success("Mise à jour effectuée avec succès!");
        })
    }

    useEffect(() => {
        console.log("test"+user.telephone);
        setValue(user.telephone);
    }, [user]);

    return(
        <div className={styles}>
            <div className="account-info-container">
                <div className="info-card">
                    <h2 className="card-title">Informations de compte</h2>
                    <div className="profile-info-account">
                    <div className="profile-details-account">
                        <label htmlFor="type" className="visually-hidden">Type de profil</label>
                        <div className="info-label">Type de profil</div>
                        <input disabled id="type" className="info-value"  defaultValue={user.type}/>

                        <label htmlFor="pseudo" className="visually-hidden">Pseudo</label>
                        <div className="info-label">Pseudo</div>
                        <input disabled id="pseudo" className="info-value"  defaultValue={user.pseudo}/>
                        
                        <label htmlFor="email" className="visually-hidden">Email</label>
                        <div className="info-label">Email</div>
                        <input id="email" className="info-value" defaultValue={user.email}/>
                        
                        <div className="name-container">
                        <div className="name-field">
                            <label htmlFor="lastname" className="visually-hidden">Nom</label>
                            <div className="info-label">Nom</div>
                            <input id="nom" className="info-value" defaultValue={user.nom}/>
                        </div>
                        <div className="name-field">
                            <label htmlFor="firstname" className="visually-hidden">Prénom</label>
                            <div className="info-label">Prénom</div>
                            <input id="prenom" className="info-value" defaultValue={user.prenom}/>
                        </div>
                        </div>
                        
                        <label htmlFor="phone" className="visually-hidden">Téléphone</label>
                        <div className="info-label">Téléphone</div>
                        <PhoneInput className="info-value"  defaultValue={user.telephone} flags={flags} value={value} onChange={setValue} />
                        <input id="telephone" className="info-value hidden" defaultValue={user.telephone}/>
                    </div>
                    </div>
                </div>
                
                <div className="info-card description-card">
                    <h2 className="card-title">Description</h2>
                    <textarea id="description-profil" className="description-text-account w-full" defaultValue={user.description} rows={10}/>
                </div>
                
                <div className="info-card social-card">
                    <h2 className="card-title">Réseaux sociaux</h2>
                    <div className="social-link">
                    <img src={linkedin} alt="LinkedIn icon" className="social-icon" />
                    <span className="social-name">Linkedin</span>
                    </div>
                    <input  id="linkedin" className="info-value w-full" defaultValue={user.linkedin}/>
                    
                    <div className="social-link">
                    <img src={facebook} alt="Facebook icon" className="social-icon" />
                    <span className="social-name">Facebook</span>
                    </div>
                    <input id="facebook" className="info-value w-full" defaultValue={user.facebook}/>
                    
                    <div className="social-link">
                    <div className="twitter-icon-wrapper">
                        <img src={twitter} alt="Twitter icon" className="twitter-icon" />
                    </div>
                    <span className="social-name">X (Twitter)</span>
                    </div>
                    <input  id="twitter" className="info-value w-full"  defaultValue={user.twitter}/>
                    
                    <div className="info-label">Site Web</div>
                    <input id="website" className="info-value w-full"  defaultValue={user.website}/>
                </div>
            </div>
            <div style={{display: 'flex', float: 'right', gap: '9px', marginTop: '20px'}}>
                <button onClick={majProfil} className="action-button accept-button">Mise à jour</button>
                <button className='bouton-voir'> <Link to="/traveler"  state={{user: user }}>Voir mon profil</Link></button>
            </div>
        </div>
    )
}
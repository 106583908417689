/* eslint-disable react/no-unescaped-entities */
import  styles from "../detail-trajet/detail-trajet.css";
import '../reservation/reservation-item.css';
import './user-trajet.css';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react'
import NavbarV2 from '../../../component/navbar-v2'
import Footer from '../../../component/footer';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import Moment from 'react-moment';
import 'moment/locale/fr';
import { useLocation, } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Luggage from '@mui/icons-material/Luggage';
import FlightTakeoff from '@mui/icons-material/FlightTakeoff';
import Boat from '@mui/icons-material/DirectionsBoat';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import ScaleIcon from '@mui/icons-material/Scale';
import CampaignIcon from '@mui/icons-material/Campaign';
import ReservationTrajet from "../../v2/reservation-trajet/reservation-trajet";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useParams } from 'react-router-dom';



export default function UserTrajet() {
  const { numero } = useParams();
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const {state} = useLocation();
    const [tabIndex, setTabIndex] = useState(0);  
    const navigate = useNavigate();
    const [trajet, setTrajet] = useState({
      numero: "",
      date_depart: "",
      date_arrivee: "",
      ville_depart: "",
      ville_arrivee: "",
      cout: "",
      capacite: "",
      description: "",
      kilospris: 0,
      visibilite_public: true,
      prix: ""

    });
    const [villes, setVilles] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [majs, setMajs] = useState([]);
    const [ville_depart, setVilleDepart] = useState({nom:"", emoji: ""});
    const [ville_arrivee, setVilleArrivee] = useState({nom:"", emoji: ""});
    const [checked, setChecked] = useState(trajet.visibilite_public);

  

    function setTravelData(trajet){
      if(trajet !== undefined){

        setTrajet({
          numero: trajet.numero,
          date_depart: trajet.date_depart,
          date_arrivee: trajet.date_arrivee,
          ville_depart: trajet.ville_depart,
          ville_arrivee: trajet.ville_arrivee,
          cout: trajet.prix,
          capacite: trajet.capacite,
          description: trajet.description,
          kilospris: trajet.kilospris,
          prix: trajet.prix
        })
        setTrajet(trajet);
        setVilleDepart({nom: trajet.ville_depart, emoji:''});
        setVilleArrivee({nom: trajet.ville_arrivee, emoji: ''});
      }

      
      
    }

    function onTabSelect(index){
      console.log("tab changed");
      setTabIndex(index);
      if(index === 0){
        //setTravelData();
      }
      if(index === 1){
        getReservations(trajet);
      }
    }

    useEffect(() => {
        async function getVilles() {
            try {
              const response = await fetch(backend_url+'/villes');
              const data = await response.json();
              setVilles(data);
            } catch (error) {
              console.error(error);
            }
        }

        
        getVilles();
        if (state?.trajet !== undefined){
          console.log(state.trajet)
          setTrajet(state.trajet);    
          setTravelData(state.trajet);
          getReservations(state.trajet);
          getMajsTrajets(state.trajet);
          setChecked(state.trajet.visibilite_public);
        }
        
        
        if (numero !== undefined ){
          getCurrentTrajet()
        }

        if (state?.trajet !== undefined){
          setTrajet(state.trajet);
        }

        
      }, []);
    
      function departChange(event, newValue){
        setVilleDepart(newValue);
        
      }
      function arriveeChange(event, newValue){
        setVilleArrivee(newValue);
        
      }
    
    
      function getReservations(trajet) {
        
        let token = localStorage.getItem("token");

        if (state?.trajet !== undefined){
          setTrajet(state.trajet);
        }

        if (trajet === undefined){

          setReservations([]);
          return
        }
        console.log(trajet);
        
        
        const requestOptions = {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        }
        };
        
        fetch(backend_url+'/user/trajet/reservations?numero_trajet='+trajet.numero, requestOptions)
        .then(response => {
            return response.json();
        })
        .then(data => {
          setReservations(data);
        })
        .catch(error => {
         console.log(error);
        });
      }
    
      function getMajsTrajets(trajet) {
        
        let token = localStorage.getItem("token");

        if (state?.trajet !== undefined){
          console.log("condition")
          setTrajet(state.trajet);
        }else{
          if (trajet === undefined){
            return
          }
        }

       
        
        const requestOptions = {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        }
        };
        
        fetch(backend_url+'/majtrajet/'+trajet.id, requestOptions)
        .then(response => {
            return response.json();
        })
        .then(data => {
          console.log(data);
          setMajs(data);
        })
        .catch(error => {
         console.log(error);
        });
      }

      function getCurrentTrajet() {
        
        let token = localStorage.getItem("token");

        if (state?.trajet !== undefined){
           setTrajet(state.trajet);
        }

        if (numero === undefined){
          return
        }
        
        const requestOptions = {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        }
        };
        
        fetch(backend_url+'/trajets/owner/get_unique?numero='+numero, requestOptions)
        .then(response => {
            return response.json();
        })
        .then(data => {
          /****  Set data to  front elements ***/
          setTrajet(data);
          setTravelData(data);
          
          getMajsTrajets(trajet);
          setChecked(trajet.visibilite_public);
          getReservations(data);
        })
        .catch(error => {
         console.log(error);
        });
      }

    
    function miseAjourTrajet(){
      let token = localStorage.getItem("token");
      trajet.capacite = document.getElementById('capacite').value ;
      trajet.description = document.getElementById('commentaire').value ;
      trajet.prix = document.getElementById('cout').value ;
      trajet.ville_depart = document.getElementById('depart-modif').value ;
      trajet.ville_arrivee = document.getElementById('arrivee-modif').value ;
      trajet.date_depart = document.getElementById('date-depart').value ;
      trajet.date_arrivee = document.getElementById('date-arrivee').value ;
      const requestOptions = {
        method: 'PATCH',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ token
      },
      body: JSON.stringify({ 
        ...trajet         
      })
      };
      
      fetch(backend_url+'/trajets/update', requestOptions)
      .then(response => {
        if (response.ok){
          toast.success("Trajet mis à jour avec succès");
        }
          return response.json();
      })
      .catch(error => {
       console.log(error);
      });

    }

    function addMajTrajet(){
      let token = localStorage.getItem("token");
      let description = document.getElementById('description').value ;

      if (description == "")
      return

      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ token
      },
      body: JSON.stringify({ 
        id_trajet: trajet.id,
        commentaire: description   
      })
      };
      
      fetch(backend_url+'/majtrajet/add', requestOptions)
      .then(response => {
        if (response.ok){
          toast.success("Trajet mis à jour avec succès");
          document.getElementById('description').value = "";
          getMajsTrajets(trajet);
        }
          return response.json();
      })
      .catch(error => {
       console.log(error);
      });

    }

    function refresh(){
      getReservations(trajet);
    }
    
    function showComments(res){
      navigate("/reservation", {state: {reservation: res, trajet: res.trajet}});
    }

  function rejectReservation(res){
      let token = localStorage.getItem("token");
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ token
      
      },
        body: JSON.stringify({ 
          ...res.trajet
          
        },)};
      
      fetch(backend_url+'/trajets/reject/reservation/'+res.id, requestOptions)
      .then(response => {

          if (response.ok) {
              toast.success("La réservation a bien eté rejetée!");
              refresh();
            }
          return response.json();
      })
      
  }

  function acceptReservation(res){
      let token = localStorage.getItem("token");
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ token
      
      },
        body: JSON.stringify({ 
          ...res.trajet
          
        },)};
      
      fetch(backend_url+'/trajets/accept/reservation/'+res.id, requestOptions)
      .then(response => {

          if (response.ok) {
              toast.success("La réservation a bien eté acceptée!");
              refresh();
            }
          return response.json();
      })
  }

  function statutAffichage(event){

    console.log(event.target.checked);
    let token = localStorage.getItem("token");
      trajet.visibilite_public = event.target.checked;
      setChecked(event.target.checked);
      //setTrajet(trajet);
      const requestOptions = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ token
      },
      body: JSON.stringify({
        ...trajet
      })
      };
      fetch(backend_url+'/trajets/visibility', requestOptions)
      .then(response => {
        if (response.ok){
          toast.success("Trajet mis à jour avec succès");
        }
          return response.json();
      })
      .catch(error => {
       console.log(error);
      });
  }

    return (
        <div className={styles}>
            <NavbarV2 />
            <ToastContainer />
            <section className="top-display py-28-b w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"  style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 bg-slate-950/80 navbar-background"></div>

                <div className="container">
                    <div className="container">
                      <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h6 className="mb-3 font-medium leading-normal text-3xl mt-10 text-white">
                          Détails du trajet
                        </h6>
                        <h1 className="text-white">
                          {trajet &&
                              <h1>Numero: {trajet.numero}</h1>
                            }
                        </h1>
                      </div>
                     </div>
                </div>
                <div className='container event-card'>
                { trajet &&
                    <div className='travel-detail-header'>
                        <div>
                            <h6  className="nom-ville" style={{color: "#ffffff"}}>{trajet.ville_depart}</h6>
                            <br/>
                            <h1 style={{color: "#ffffff"}}>Départ: {trajet.date_depart}</h1>
                        </div>
                        <div >
                            {trajet.type === "Avion"  &&
                            <FlightTakeoff className='colismi-icon' sx={{ fontSize: 80, color: '#ffff' }} />
                            }
                            {trajet.type === "Fret aerien"  &&
                            <FlightTakeoff className='colismi-icon' sx={{ fontSize: 80, color: '#ffff' }} />
                            }
                            {trajet.type === "Bateau"  &&
                            <Boat className='colismi-icon' sx={{ fontSize: 80, color: '#ffff' }} />
                            }
                            
                        </div>
                        
                        <div>
                          <h1 className="nom-ville" style={{color: "#ffffff"}}>{trajet.ville_arrivee}</h1>
                          <br/>
                           <h1 style={{color: "#ffffff"}}>Arrivée: {trajet.date_arrivee}</h1>
                        </div>
                    </div>
                    }
                </div>
                <div className="container">
                    
                </div>
            </section>
            <section className="main-section">
                <div className="container content-zone">
                   
                    
                    <div className="section-sidebar">
                        <Tabs
                        selectedIndex={tabIndex}
                        onSelect={(index) => onTabSelect(index)}
                        className="react-tabs"
                        
                        >
                            <TabList>
                            
                                <Tab>Détails du trajet</Tab>
                                <Tab>Réservations</Tab>
                                <Tab>Mises à jour</Tab>
                            </TabList>
                            

                            <TabPanel>
                                  <FormControlLabel control={<Switch  checked={checked} color="secondary"  onChange={statutAffichage} />} label="Afficher mon trajet dans les recherches"  />
                                  <div id='stats-trajet'>
                                      <div className="stats-trajet-item">
                                          <BookOnlineIcon color="secondary" sx={{ fontSize: 30, color: '#7f5990' }} />
                                          <h1 className="texte-stat"> Réservations</h1>
                                          <p>{reservations.length}</p>
                                      </div>
                                      <div className="stats-trajet-item">
                                        <ScaleIcon color="secondary" sx={{ fontSize: 30, color: '#7f5990' }} />
                                          <h1 className="texte-stat"> Kilos pris</h1>
                                          <p>{trajet.kilospris}</p>
                                      </div>
                                      <div className="stats-trajet-item">
                                        <Luggage color="secondary" sx={{ fontSize: 30, color: '#7f5990' }} />
                                          <h1 className="texte-stat"> Capacité</h1>
                                          <p>{trajet.capacite} Kg</p>
                                      </div>
                                </div>
                                <div className="lg:col-span-5 md:col-span-6">
                                <div className="lg:ms-5">
                                    <div className="bg-white dark:bg-slate-900 rounded-md">
                                        <form name="myForm" id="myForm">
                                            <p className="mb-0" id="error-msg"></p>
                                            <div id="simple-msg"></div>

                                            <div className="grid grid-cols-1">
                                                <div className="flex items-center mb-5 mr-5">
                                                    <label htmlFor="pseudo" className="compte-label form-label font-medium  mr-10">Ville départ:</label>
                                                    <Autocomplete
                                                        className='autocomplete w-full'
                                                        id="depart-modif"
                                                        value={ville_depart}
                                                        onChange={departChange}
                                                        options={villes}
                                                        sx={{ minWidth: 300 }}
                                                        getOptionLabel={(option) => option.emoji + " " + option.nom}
                                                        renderInput={(params) => <TextField {...params} label="" id="input-depart"  variant="outlined"   style={{ backgroundColor: 'white' }} />}
                                                        />
                                                </div>
                                                <div className="flex items-center mb-5 mr-5">
                                                    <label htmlFor="nom" className="compte-label form-label font-medium  mr-10">Ville arrivée:</label>
                                                    <Autocomplete
                                                      className='autocomplete w-full'
                                                      id="arrivee-modif"
                                                      value={ville_arrivee}
                                                      options={villes}
                                                      onChange={arriveeChange}
                                                      sx={{ minWidth: 300 }}
                                                      getOptionLabel={(option) => option.emoji + " " + option.nom }
                                                      renderInput={(params) => <TextField  {...params} label="" id="input-arrivee" variant="outlined"   style={{ backgroundColor: 'white' }} />}
                                                      />   
                                                </div>
                                                <div className="flex items-center mb-5 mr-5">
                                                    <label htmlFor="date-depart" className="compte-label form-label font-medium  mr-10">Date départ:</label>
                                                    <input type="date" defaultValue={trajet.date_depart} name="date-depart" id="date-depart" className="form-compte form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                                </div>
                                                <div className="flex items-center mb-5 mr-5">
                                                    <label htmlFor="date-arrivee" className="compte-label form-label font-medium  mr-10">Date d'arrivée:</label>
                                                    <input type="date" name="date-arrivee" defaultValue={trajet.date_arrivee} id="date-arrivee" className="form-compte form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                                </div>
                                                <div className="flex items-center mb-5 mr-5">
                                                    <label htmlFor="capacite" className="compte-label form-label font-medium  mr-10">Capacité (Kg):</label>
                                                    <input name="capacite" id="capacite" type="number" defaultValue={trajet.capacite} className="form-compte form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                                </div>
                                                <div className="flex items-center mb-5 mr-5">
                                                    <label htmlFor="cout" className="compte-label form-label font-medium  mr-10">Prix par Kg(€):</label>
                                                    <input name="cout" id="cout" type="number" defaultValue={trajet.prix} className="form-compte form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                                </div>
                                                
                                                <div className="flex items-center mb-5 mr-5">
                                                    <label htmlFor="twitter" className="compte-label form-label font-medium  mr-10">Description:</label>
                                                    <textarea name="travel-commentaire"
                                                     id="commentaire" 
                                                     defaultValue={trajet.description}
                                                     className="form-commentaire form-compte form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" 
                                                     placeholder="" 
                                                     rows={10}
                                                     
                                                     />
                                                </div>

                                            </div>
                                        </form>
                                        <button  id="submit" name="send" onClick={miseAjourTrajet} className="action-button accept-button" style={{float: 'right'}}>Mise à jour</button>
                                    </div>
                                </div>
                            </div>
                            </TabPanel>
                            <TabPanel id='panel-trajets'>
                              <br/>
                              { reservations.length ===0 &&
                                <p style={{color: 'darkgrey'}}>Aucune réservation en cours</p>
                              }
                            
                            {
                              reservations.map(reservation => (

                                <ReservationTrajet
                                key={reservation.id}
                                reservation={reservation}
                                func_accepter={() => acceptReservation(reservation)}
                                func_refuser={ () => rejectReservation(reservation)}
                                func_chat={() => showComments(reservation)}
                                
                                />
                              ))

                            }
                            </TabPanel>
                            <TabPanel>
                            <div>
                                
                                <div className="mb-5">
                                    <label htmlFor="description" className="form-label font-medium"><strong>Ajoutez des mises à jour pour le trajet en cours</strong></label>
                                    <textarea required 
                                      name="description" 
                                      id="description" 
                                      placeholder='Ajoutez des mises à jour pour le trajet en cours'
                                      className="input-wrapper w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2 textarea h-28"></textarea>
                                </div>
                                <button id="submit" onClick={addMajTrajet} name="send" className="colismi-button-cm">Envoyer</button>

                                {
                                  majs.map(majtrajet =>(
                                    <div className="majtrajet" key={majtrajet.date_ajout}>
                                        <div className="majtrajet-left">
                                            <div>
                                                <CampaignIcon className='colismi-icon' sx={{ fontSize: 60, color: '#7f5990' }} />

                                            </div>
                                            <div>
                                                <p><Moment fromNow ago>{majtrajet.date_ajout}</Moment></p>
                                            </div>
                                            
                                       </div>
                                       <div className="majtrajet-right">
                                            {majtrajet.commentaire}
                                        </div>
                                    </div>
                                  ))
                                }
                                
                            </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
                
            </section>
            <Footer />
        </div>
    )
}
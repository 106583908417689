/* eslint-disable react/no-unescaped-entities */
import '../../../App.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import styles from "./travel-search.css";
import React, { useEffect, useState } from 'react'
import NavbarV2 from '../../../component/navbar-v2.js'
import Footer from '../../../component/footer.js';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';
import no_result from '../../../assets/images/colismi/Illustration-erreur.svg'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import TravelItemV2 from '../../v2/travel/travel-item.js';
import ModalReservation from '../../v2/modal-reservation/modal-reservation.js';



export default function TravelSearch() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const {state} = useLocation();
    const [villes, setVilles] = useState([]);
    const [villeArrivee, setVilleArrivee] = useState({nom:"", emoji:""});
    const [villeDepart, setVilleDepart] = useState({nom:"", emoji:""});
    const [isLoggedin, setIsLoggedin] = useState(false);
    const [trajets, setTrajets] = useState([]);
    const [selectedTrajet, setSelectedTrajet] = useState(undefined);
    const [display_trajets, setDisplayedTrajets] = useState([]);
    const item_per_page = 10;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (data) => {
      console.log(data);
      setSelectedTrajet(data);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };


    function search(ville_depart, ville_arrivee) {
      // GET request using fetch with set headers
      
      console.log(ville_arrivee);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          ville_depart: ville_depart,
          ville_arrivee: ville_arrivee
        })};
      
      fetch(backend_url+'/trajets/search', requestOptions)
      .then(response => {
          return response.json();
      })
      .then(data => {
          setTrajets(data);
          setDisplayedTrajets(data.slice(0, item_per_page));
      })
      .catch(error => {
       console.log(error);
      });
  }
   

    useEffect(() => {

      document.title = "Colismi - Recherchez des transporteurs";

        async function getVilles() {
            try {
              const response = await fetch(backend_url+'/villes');
              const data = await response.json();
              setVilles(data);
            } catch (error) {
              console.error(error);
            }
        }

        async function searchTrajets(ville_depart, ville_arrivee) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                  ville_depart: ville_depart,
                  ville_arrivee: ville_arrivee
            })
            };
            try {
              const response = await fetch(backend_url+'/trajets/search', requestOptions);
              const data = await response.json();
              setTrajets(data);
              setDisplayedTrajets(data.slice(0, item_per_page));
            } catch (error) {
              console.error(error);
            }
        }

        getVilles();
        if (state && (state.arrivee !=='' && state.depart !== '')){
          
          search(state.depart, state.arrivee);
        }else{
          searchTrajets();
        }
        
        let token = localStorage.getItem("token");
        if(token == undefined){
          setIsLoggedin(false);
        }else{
          setIsLoggedin(true);
        }

        if(state != undefined && state.depart !== undefined && state.arrivee !== undefined ){
          // TODO: TO be done properly: retrieve emoji and name separately as sent from index
          setVilleDepart({nom: state.depart, emoji:""});
          setVilleArrivee({nom: state.arrivee, emoji: ""});
        }
        
      }, []);

      function searchTravel(){
        const depart = document.getElementById('search-depart').value;
        const arrivee = document.getElementById('search-arrivee').value;    
        search(depart, arrivee);
    }

    function handlePagination(event , page){

      setDisplayedTrajets(trajets.slice((page-1)*item_per_page, page*item_per_page));
    }

    function envoiReservation(){
      let token = localStorage.getItem("token");

      //const email = document.getElementById('reserv-email').value;
      const commentaire = document.getElementById('reserv-commentaire').value;  
      const kilos = document.getElementById('reserv-kilos').value;
      
      console.log("passing here");
      if (commentaire === undefined || commentaire === ""){
        toast.error("Le commentaire ne doit pas être vide");
        return
      }
      if (kilos === undefined || kilos === ""){
        toast.error("Le nombre de kilos ne doit pas être vide");
        return
      }

      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token      
      },
        body: JSON.stringify({ 
          email: "",
          telephone: "",
          commentaire: commentaire,
          kilos: kilos,
          ville_depart: selectedTrajet.ville_depart ,
          ville_arrivee: selectedTrajet.ville_arrivee,
          numero_trajet: selectedTrajet.numero,
          tarif_kilos: 0
        })};
      
      fetch(backend_url+'/reservation/add', requestOptions)
      .then(response => {
          if (response.ok){
            toast.success("Réservation bien enregistrée");
            setOpen(false);
          }
          return response.json();
      })
      .then(data => {
        if(data.detail != undefined){
          toast.error(data.detail);
        }
      })
    }

    function departChanged(event, newValue){
        setVilleDepart(newValue);
    }

    function arriveeChanged(event, newValue){
        setVilleArrivee(newValue);
    }


    return (
        <div className={styles}>
            <NavbarV2 />
            <ToastContainer />
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-6.jpg')]" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

               

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h6 className="font-medium leading-normal text-3xl mt-10 text-white">Besoin de transporter un colis?</h6>
                        <h1 className="font-medium leading-normal text-sm mt-1 text-white">Recherchez parmi les annonces disponible.</h1>
                    </div>
                </div>
            </section>
            <section>
                <div className="create-annonce">
                         <button   className="colismi-button-nobg boutons-action py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center text-white rounded-md" style={{backgroundColor:'#3ebc6e'}} > <Link to="/travel-add">Je propose un trajet</Link></button>
                         <button    className="colismi-button-nobg boutons-action py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center text-white rounded-md" style={{backgroundColor:'#3ebc6e'}}> <Link to="/colis-request">Deposer une demande</Link></button>
                 </div>
                <br />
                <div id="main-body">

                
                <div className='search-sidebar'>
                <h1>Effectuez votre recherche ici</h1>
                <div className="search-zone">
                            <Autocomplete
                                    className='autocomplete'
                                    id="search-depart"
                                    value={villeDepart}
                                    options={villes}
                                    onChange={departChanged}
                                    sx={{ minWidth: 300, borderRadius: 30, zIndex:0 }}
                                    getOptionLabel={(option) => option.emoji + " " + option.nom  }
                                    renderInput={(params) => <TextField {...params} label="Ville de départ" id='s-depart'  variant="outlined" style={{ backgroundColor: 'white' }} />}
                                    />
                            <Autocomplete
                                    className='autocomplete'
                                    value={villeArrivee}
                                    id="search-arrivee"
                                    options={villes}
                                    onChange={arriveeChanged}
                                    sx={{ minWidth: 300,  borderRadius: 30, zIndex:0  }}
                                    getOptionLabel={(option) => option.emoji + " " + option.nom  }
                                    renderInput={(params) => <TextField {...params} label="Ville d'arrivée" id='s-arrivee'  variant="outlined" style={{ backgroundColor: 'white' }} />}
                                    />   

                            <Button onClick={searchTravel} className="colismi-button" >Recherche</Button> 

                                                       
                            

                 </div>

                </div>
                <div className='ccont'>
                         { display_trajets.length>0? (
                          <div>{
                            display_trajets.map((item, index) =>(
                                    <TravelItemV2
                                      key={index}
                                      {...item}
                                      onClick={() => handleClickOpen(item)}
                                    
                                    />
                                ))
                              }
                              <div className='container'>
                                  <Pagination count={Math.ceil(trajets.length/item_per_page)} color="secondary"  onChange={handlePagination} />
                              </div>
                        </div>
                         )

                        :(
                          <div>
                            <p className='text-no-result text-slate-400 dark:text-slate-300'>Aucun resultat trouvé, N'hesitez pas à poster une demande pour que
                            les transporteurs vous recontactent. Cela vous prendra 1mn</p>
                          <div className='container no-result'>

                              
                              <img src={no_result} className='image-no-result'/>

                          </div>
                          <div className='extra-action-no-result'>
                                <br/>
                          </div>
                          
                        </div>
                          
                        )
                        }
                </div>
                </div>
                
            </section>
            <Footer />

            <Dialog
             
              open={false}
              onClose={handleClose}
              PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  const email = formJson.email;
                  console.log(email);
                  handleClose();

                },
                style: {
                  backgroundColor: '#ffeea',
                  boxShadow: 'none',
                },
                
              }}
            >
        <DialogTitle
        sx={{
          backgroundColor: '#d7cce6'

        }}
        > <strong>Ecrire au voyageur</strong></DialogTitle>
        {isLoggedin && 
        <DialogContent
        
        >
          <DialogContentText>
            Donnez plus de détails par rapport à votre requete afin que le voyageur
            puisse vous recontacter
          </DialogContentText>
          
          <TextField
            autoFocus
            required
            margin="dense"
            id="reserv-kilos"
            name="kilos"
            label="Poids du colis"
            type="number"
            fullWidth
            variant="standard"
          />

          <TextField
            autoFocus
            required
            margin="dense"
            id="reserv-commentaire"
            name="commentaire"
            label="commentaire"
            type="text"
            multiline
            fullWidth
            minRows={4}
            maxRows={6}
            variant="standard"
          />

         
        
        </DialogContent>
        }
        {!isLoggedin &&
          <DialogContent
        
          >
            <DialogContentText>
               Vous devez vous connecter d'abord avant de contacter le voyageur.
               ça vous prendra 1mn.
            </DialogContentText>
            <button className='colismi-button-cm'><Link to='/signup'> Créer mon compte </Link> </button>
           
  
            
          
          </DialogContent>
        }
        
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          { isLoggedin &&
            <Button onClick={envoiReservation}>Valider</Button>
          }
          
        </DialogActions>
      </Dialog>
      <ModalReservation
         open={open}
         func_close={handleClose}
         func_valider={envoiReservation}
         trajet={selectedTrajet}
        />
        </div>
    )
}

import React from 'react';
import { Link, useLocation } from "react-router-dom";
// import imagePlaceholder from '../../../assets/images/client/02.jpg'; // Adjust the import path as necessary
import imagePlaceholder from '../../../assets/images/colismi/trajet/user.svg'; // Adjust the import path as necessary
// import profil from '../../../assets/images/colismi/trajet/user.svg';

/* eslint-disable react/no-unescaped-entities */
import styles from "../detail-trajet/detail-trajet.css";
import '../reservation/reservation-item.css';
import './user-review.css';
import 'react-toastify/dist/ReactToastify.css';
import NavbarV2 from '../../../component/navbar-v2';
import Footer from '../../../component/footer';
import { ToastContainer} from 'react-toastify';
import 'moment/locale/fr';
import moment from 'moment-timezone';
import FlightTakeoff from '@mui/icons-material/FlightTakeoff';
import Boat from '@mui/icons-material/DirectionsBoat';
import depart from '../../../assets/images/colismi/trajet/depart_couleur.svg';
import arrivee from '../../../assets/images/colismi/trajet/arrivee_couleur.svg';
moment.locale('fr');


export default function UserReview() {
    const location = useLocation();
    const reviews = location.state?.user_reviews || [];
    const meanRating = location.state?.user_mean_rating || 0;
    const pseudo = location.state?.user_pseudo;

    return (
        <div className={styles}>
            <NavbarV2 />
            <ToastContainer />
            <section className="top-display py-3 w-full table relative bg-[url('../../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{ backgroundSize: 'cover' }} id="home">
                <div className="absolute inset-0 bg-slate-950/80 navbar-background"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h6 className="mb-3 font-medium leading-normal text-3xl mt-10 text-white">Avis des Clients</h6>
                        <p className="text-slate-400 max-w-xl mx-auto">Découvrez les avis de nos clients ayant fait appel aux services de <span className="profile-name-review">{pseudo}</span>.</p>
                        <p className="text-slate-400 max-w-xl mx-auto">Vos retours sont essentiels pour nous améliorer continuellement.</p>
                        <div className="flex justify-center items-center mt-4">
                        {/* <img loading="lazy" src={profil} className="profile-avatar" alt="Didi's profile picture" />
                        <h2 className="profile-name">{pseudo}</h2>
                        <div></div> */}
                        <div className="mt-2">
                            <ul className="list-none mb-0 text-amber-400 flex justify-center ml-2">
                                {[...Array(Math.round(meanRating))].map((_, i) => (
                                    <li key={i} className="inline"><i className="mdi mdi-star"></i></li>
                                ))}
                                {[...Array(5 - Math.round(meanRating))].map((_, i) => (
                                    <li key={i} className="inline"><i className="mdi mdi-star-outline"></i></li>
                                ))}
                            </ul>
                            <span className="text-white text-lg ml-5">Note: {meanRating.toFixed(1)} </span>
                        </div> 
                        </div>                    
                    </div>
                </div>
            </section>

            <section className="main-section py-16">
                <div className="container">
                    <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        {reviews.length === 0 ? (
                            <p style={{ color: 'darkgrey' }}>Aucun avis pour le moment !</p>
                        ) : (
                            reviews.map((review, index) => (
                                <div key={index} className="space-y-8">
                                    <div className="rounded-lg shadow-lg dark:shadow-gray-800 p-2 bg-white dark:bg-slate-900">
                                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                            <img src={review.image || imagePlaceholder} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt={review.reviewer.pseudo} />
                                            <div className="ps-4">
                                                <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">{review.reviewer.pseudo}</Link>
                                                <p className="text-slate-400">{review.reviewer.type}</p>
                                                <p className="text-slate-400">{moment.utc(review.date_ajout).local().format('LLL')}</p> {/* Display creation date */}
                                            </div>
                                        </div>

                                        <div className="shipment-card">
                                            <Link to={`/trajet/${review.trajet.numero}`} className="text-blue-500 hover:underline">
                                            <div className="shipment-header">
                                                <div className="shipment-details">
                                                <div className="location-info">
                                                    <div className="location-wrapper">
                                                        <img src={depart} alt="Departure location icon" className="location-icon" />
                                                        <div className="location-name">{review.trajet.ville_depart}</div>
                                                    </div>
                                                    <div className="date">{review.trajet.date_depart}</div>
                                                </div>
                                                {review.trajet.type === "Avion"  &&
                                                                                            <FlightTakeoff className='colismi-icon' sx={{ fontSize: 40, color: '#5f2972' }} />

                                                                                        }
                                                                                        {review.trajet.type === "Fret Aerien"  &&
                                                                                            <FlightTakeoff className='colismi-icon' sx={{ fontSize: 40, color: '#5f2972' }} />

                                                                                        }
                                                                                        {review.trajet.type === "Bateau"  &&
                                                                                            <Boat className='colismi-icon' sx={{ fontSize: 40, color: '#5f2972' }} />

                                                                                        }
                                                <div className="location-info">
                                                    <div className="location-wrapper">
                                                    <img src={arrivee} alt="Arrival location icon" className="location-icon" />
                                                    <div className="location-name">{review.trajet.ville_arrivee}</div>
                                                    </div>
                                                    <div className="date">{review.trajet.date_arrivee}</div>
                                                </div>

                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                        
                                        <div className="mt-6">
                                            <p className="text-slate-400">{review.text}</p>
                                            <ul className="list-none mb-0 text-amber-400 mt-2">
                                                {[...Array(review.rating)].map((_, i) => (
                                                    <li key={i} className="inline"><i className="mdi mdi-star"></i></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))

                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

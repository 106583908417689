/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import styles from './comments-demande.css';
import NavbarV2 from '../../../component/navbar-v2';
import Footer from '../../../component/footer';
import { useLocation, } from 'react-router-dom';
import 'moment/locale/fr';
import { ToastContainer, toast } from 'react-toastify';
import Commentaire from '../../v2/commentaire/commentaire';
import fleche from '../../../assets/images/colismi/trajet/fleche.svg';
import { useNavigate } from 'react-router-dom';


export default function CommentsDemande() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const {state} = useLocation();
    const [demande, setDemande] = useState(undefined);
    const [reponses, setReponses] = useState([]);
    const navigate = useNavigate();

            
    useEffect(()=>{
        if(state.demande != undefined){
            setDemande(state.demande);
            setReponses(state.demande.reponses);
            console.log(demande);
        }
    
    }, []);

    function createDiscussion(response_choice){
        let endpoint = "/discussions";
        let headers = {};
        let token = localStorage.getItem("token");
        headers = { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
        }

    


        
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ 
            texte: "",
            objet: "",
            numero: "",
            id_emetteur: demande.id_user,
            id_recepteur: response_choice.id_user
          
          })};
        
        fetch(backend_url+endpoint, requestOptions)
        .then(response => {
            
            return response.json();
        })
        .then(data => {
            if(data.detail != undefined){
                toast.error(data.detail);
                
            }else{
                toast.success("Discussion crée avec succès!");
                navigate("/chat")
            }
        
        })
        .catch(error => {
            
            console.log("error")
            navigate("/chat")
            console.log(error);
        });
      }

   
    return (
        <div className={styles}>
            <ToastContainer />
            <body className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
                <NavbarV2 />
                <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"
                    id="home"
                    style={{backgroundSize:'cover'}} 
                    >
                    <div className="absolute inset-0 bg-slate-950/80 navbar-background"></div>
                    <div className="container">
                        <div className="grid grid-cols-1 pb-8 text-center mt-10">
                                <h1 style={{fontSize: '22px'}} className='text-white'>Votre Demande</h1>
                        </div>

                        
                    </div>
                    <div className='container event-card'>
                        { demande &&
                            <div className='travel-detail-header'>
                                <div>
                                    <h6  className="nom-ville" style={{color: "#ffffff"}}>{demande.ville_depart}</h6>
                                    <br/>
                                    
                                </div>
                                <div>
                                     <img loading="lazy" src={fleche} className="trip-arrow" alt="Trip direction arrow" />
                                </div>
                            
                                
                                <div>
                                <h1 className="nom-ville" style={{color: "#ffffff"}}>{demande.ville_arrivee}</h1>
                                <br/>
                                </div>
                            </div>
                            }
                </div>
                    <div className='container'>
                    

                    </div>
                </section>
                <section className="relative" id="about">
                    <div id='content-part'>
                        <div className='details-contenu'>

                           

                            <div >
                                    <div className="profile-details">                          
                                
                                        <h3 className="titre-description">Description</h3>                             
                                        <p>{demande &&
                                                        demande.description}</p>
                                                        <br/>
                                            
                                    </div>
                                

                            </div>

                            <div>
                                   
                                    <div>
                                        <strong>Réponses</strong>
                                        <br/>
                                        {
                                            reponses.map( reponse =>(
                                                <Commentaire
                                                key={reponse.id}
                                                commentaire={reponse}
                                                btn_create={()=>createDiscussion(reponse)}
                                                />

                                            ))
                                        }
                                    </div>
                        
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </body>
            
        </div>
    )
}


/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from './reservation-trajet.css';
import { Link } from "react-router-dom";
import prix from '../../../assets/images/colismi/trajet/prix.svg'
import date from '../../../assets/images/colismi/trajet/date.svg'
import HeightIcon from '@mui/icons-material/Height';
import WidthFullOutlinedIcon from '@mui/icons-material/WidthFullOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';

import poids from '../../../assets/images/colismi/trajet/poids.svg'

import profil from '../../../assets/images/colismi/trajet/user.svg';
import description from '../../../assets/images/colismi/trajet/description.svg';

import moment from 'moment-timezone';
moment.locale('fr');


export default function ReservationTrajet({reservation, func_accepter, func_refuser, func_chat}){

    // Format date with Moment.js
    let formattedDate = "";
    try {
        formattedDate = moment.utc(reservation.date_ajout).fromNow();
    } catch (error) {
        console.error("Error parsing date:", error);
        formattedDate = "Invalid Date";
    }

    return (
        <div style={styles} className='parent-review-card'>
        <div className="review-card">
        <div className="review-header">
            <div className="reviewer-info">
            <div className="reviewer-profile">
                <img src={profil} alt="John Doe's profile picture" className="profile-image" />
                <div className="name-rating">
                <div className="reviewer-name"> <Link to="/traveler"  state={{ user: reservation.sender }}>{reservation.sender.pseudo}</Link></div>
                <div className="rating-container hidden">
                    <div className="rating-value hidden">4.5</div>
                    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/d2a17639e223d5c69dfbd0af2899b20dfa8ea9fa06dbcb8d5c1d8d920e8bbeed?apiKey=0b65d8b49c0846d0bfbbad44662cd24e&" alt="" className="star-icon" />
                    <div className="review-count hidden">(6)</div>
                </div>
                </div>
            </div>
            <div className="review-date">
                {reservation.statut === "ACCEPTE"  &&
                    <div className="status-badge-actif">Accepté</div>

                }
                {reservation.statut !== "ACCEPTE"  && reservation.statut !== ""  &&
                    <div className="status-badge-annuler">{reservation.statut}</div>
                }
                {reservation.statut === ""  &&
                    <div className="status-badge-annuler">En attente</div>
                }
            </div>
           
            </div>

        </div>
        <div className="review-details">
            <div className='review-items-info'>
                <div className="weight-info">
                    <img src={date} alt="" className="weight-icon" />
                    <div className="weight-text">Il y'a {formattedDate}</div>
                </div>
                <div className="weight-info">
                    <img src={poids} alt="" className="weight-icon" />
                    <div className="weight-text">Poids: {reservation.kilos}kg</div>
                </div>
                <div className="weight-info">
                    <img src={prix} alt="" className="weight-icon" />
                    <div className="weight-text">Prix: {reservation.tarif_kilos}€</div>
                </div>
                <div className="weight-info">
                    <StraightenOutlinedIcon/>
                    <div className="weight-text">Longueur: {reservation.longueur}</div>
                </div>
                <div className="weight-info">
                    <WidthFullOutlinedIcon/>
                    <div className="weight-text">Largeur: {reservation.largeur}</div>
                </div>
                <div className="weight-info">
                    <HeightIcon/>
                    <div className="weight-text">Hauteur: {reservation.hauteur}</div>
                </div>

            </div>
            
            <div className="review-content">
                <img src={description} alt="" className="quote-icon" />
                <div className="review-text">
                    "{reservation.commentaire}"
                </div>
            </div>
        </div>
        <div className="action-section">
            <div className="divider"></div>
            <div className="action-buttons">
            <button className="action-button chat-button" onClick={func_chat}>
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9afcfc44fa52581db6fde9df04c8556fc51eb8a8778f5e2e0cf972737340e155?apiKey=0b65d8b49c0846d0bfbbad44662cd24e&" alt="" className="action-icon" />
                Commentaires
            </button>
            <button className="action-button reject-button" onClick={func_refuser}>
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/8ca92e570de329a787eae3c4adc3b6e12fe7896276f5ec7d1dd1a33dad4e56f2?apiKey=0b65d8b49c0846d0bfbbad44662cd24e&" alt="" className="action-icon" />
                Rejeter
            </button>
            <button className="action-button accept-button" onClick={func_accepter}>
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/d740bcd70de113d2a399b2e2667a26e3d294ddad401844dea35cfdfb187a1d74?apiKey=0b65d8b49c0846d0bfbbad44662cd24e&" alt="" className="action-icon" />
                Accepter
            </button>
            </div>
        </div>
    </div>
    </div>

    );
}
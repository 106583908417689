/* eslint-disable react/prop-types */
import styles from './modal-reservation.css';
import React, { useEffect, useState, useRef } from 'react';
import 'moment/locale/fr';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


export default function ModalReservation({open, trajet, func_close}){
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    //const [trajet] = useState(data.trajet);
    const [isLoggedin, setIsLoggedin] = useState(false);
    const modalRef = useRef();

    const handleClickOutside = (event) => {

      if (modalRef.current == event.target) {
         func_close()
      }
  };
  
    function sendReservation(){
        let token = localStorage.getItem("token");

        const commentaire = document.getElementById('description').value;  
        const kilos = document.getElementById('kilos').value;
        const longueur = document.getElementById('longueur').value;
        const largeur = document.getElementById('largeur').value;
        const hauteur = document.getElementById('hauteur').value;
  
    
        if (commentaire == undefined || commentaire == ""){
            toast.error("Le commentaire ne doit pas être vide");
            return
        }
        if (kilos == undefined || kilos == ""){
            toast.error("Le nombre de kilos ne doit pas être vide");
            return
        }
        if (longueur == undefined || longueur == ""){
          toast.error("La longueur ne doit pas être vide");
          return
        }
        if (largeur == undefined || largeur == ""){
          toast.error("La largeur ne doit pas être vide");
          return
        }
        if (hauteur == undefined || hauteur == ""){
          toast.error("La hauteur ne doit pas être vide");
          return
        }
            
        const requestOptions = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
        
        },
          body: JSON.stringify({ 
            email: "",
            telephone: "",
            commentaire: commentaire,
            kilos: kilos,
            ville_depart: trajet.ville_depart ,
            ville_arrivee: trajet.ville_arrivee,
            numero_trajet: trajet.numero,
            tarif_kilos: 0,
            longueur: longueur,
            largeur: largeur,
            hauteur: hauteur
          })};
        
        fetch(backend_url+'/reservation/add', requestOptions)
        .then(response => {

            if (response.ok) {
                toast.success("Votre demande a bien eté envoyée!");
                setTimeout(() => {
                  func_close()
                }, "2000");
              }
            return response.json();
        })
        .then(data => {
          if(data.detail != undefined){
            toast.error(data.detail);
          }
        })
      }
      

    
      useEffect(() => {

        let token = localStorage.getItem("token");
        if(token == undefined){
          setIsLoggedin(false)
        }else{
          setIsLoggedin(true)
        }
    
        
      }, []);
    
    if(!open){

      return null
    }

    return(
      <div className="reponse-modal-parent" onClick={handleClickOutside} ref={modalRef}>
        <div className={styles}>
            <ToastContainer />
            {isLoggedin &&
            <div className="contact-form-reservation">
            <h2 className="form-title">Contacter le Transporteur</h2>
            <p className="form-description">
            Vous êtes intéressé par ce trajet ?<br />
            Remplissez les information relative à votre colis afin que le transporteur vous recontacte.
            </p>
            <div className="form-divider"></div>
            <div className="form-fields">
            <div className="field-group">
                <label htmlFor="weight" className="field-label">Poids du colis (Kg) :</label>
                <input id="kilos" type="number" className="dimension-input input-kilos" placeholder="Renseignez le poids de votre colis" aria-label="Poids du colis" />
            </div>
            <div className="field-group">
                <label className="field-label">Dimension du colis (cm) :</label>
                <div className="dimensions-group">
                <input type="text" id="longueur" className="dimension-input" placeholder="Longueur" aria-label="Hauteur du colis" />
                <input type="text" id="largeur" className="dimension-input" placeholder="Largeur" aria-label="Largeur du colis" />
                <input type="text" id="hauteur" className="dimension-input" placeholder="Hauteur" aria-label="Profondeur du colis" />
                </div>
            </div>
            </div>
            <label htmlFor="description" className="description-label">Description de votre colis :</label>
            <textarea id="description" 
                    className="description-input" 
                    placeholder="Décrivez votre colis pour aider le transporteur" 
                    aria-label="Description de votre colis"
                    rows="10"
                    ></textarea>
            <div className="form-actions">
                <button type="button" className="cancel-button" onClick={func_close}>Annuler</button>
                <button type="submit" className="submit-button"  onClick={sendReservation}>Envoyer ma demande</button>
            </div>
        </div>
        }
        { !isLoggedin &&

            <div className="client-input contact-form-reservation">
            <p>Connectez vous pour contacter le voyageur</p>

            <button className='colismi-button-cm'><Link to='/signup'> Créer mon compte/Login </Link> </button>
            </div>


        }
      </div>
      </div>
    );
}
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useRef }  from 'react';
import './modal-reponse.css';
import { Link } from "react-router-dom";



export default function ModalReponse({open, func_close, func_valider, colis}){
    const [isLoggedin, setIsLoggedin] = useState(false);
    const modalRef = useRef();

    const handleClickOutside = (event) => {

        if (modalRef.current == event.target) {
           func_close()
        }
    };

    useEffect(() => {

        let token = localStorage.getItem("token");
        if(token == undefined){
          setIsLoggedin(false)
        }else{
          setIsLoggedin(true)
        }
    
        
      }, []);

    if(!open){
        return null
    }

  
    return(
        <div className="reponse-modal-parent" onClick={handleClickOutside} ref={modalRef}>
            {isLoggedin &&
            <div className="client-input contact-form-reponse">
                <h2 className="modal-title">Contacter l'Utilisateur</h2>
                <p className="modal-description">
                    Vous êtes disponible pour prendre en charge le Colis de 
                    <span className="user-name"> {colis.pseudo}</span> ?
                    <br />
                    Ou vous avez des informations utiles
                    <br/>
                    Faites le lui savoir !
                </p>
                <div className="modal-divider"></div>
                <label htmlFor="message-input" className="message-label">Message</label>
                <textarea id="message-input" className="message-input" placeholder={"Répondez à la demande de "+ colis.pseudo} aria-label="Répondez à la demande de John Doe"></textarea>
                <div className="button-container">
                    <button type="button" className="cancel-button" onClick={func_close}>Annuler</button>
                    <button type="submit" className="send-button" onClick={func_valider}>Envoyer</button>
                </div>
            </div>
            }
            { !isLoggedin &&

                <div className="modal-reponse-not-connected">
                <p>Connectez vous pour contacter le voyageur</p>

                <button className='colismi-button-cm'><Link to='/signup'> Créer mon compte/Login </Link> </button>

                </div>

            }
        </div>

    );

}
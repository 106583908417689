/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import './comments-travel.css';
import NavbarV2 from '../../../component/navbar-v2'
import Footer from '../../../component/footer';
import { useLocation, } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/fr';
import FlightTakeoff from '@mui/icons-material/FlightTakeoff';
import Boat from '@mui/icons-material/DirectionsBoat';
import { ToastContainer, toast } from 'react-toastify';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import CampaignIcon from '@mui/icons-material/Campaign';

import depart from '../../../assets/images/colismi/trajet/depart.svg'
import arrivee from '../../../assets/images/colismi/trajet/arrivee.svg'
import prix from '../../../assets/images/colismi/trajet/prix.svg'
import poids from '../../../assets/images/colismi/trajet/poids.svg'
import date from '../../../assets/images/colismi/trajet/date.svg'
import user_icon from '../../../assets/images/colismi/trajet/user.svg'

import facebook from '../../../assets/images/colismi/reseaux/facebook.svg';
import twitter from '../../../assets/images/colismi/reseaux/twitter.svg';
import whatsapp from '../../../assets/images/colismi/reseaux/whatsapp.svg';

import profil from '../../../assets/images/colismi/trajet/user.svg';
import description from '../../../assets/images/colismi/trajet/description.svg';

import { Link } from "react-router-dom";
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';

moment.locale('fr');

export default function CommentsTravel() {
    const { numero } = useParams();
    const backend_url =process.env.REACT_APP_BACKEND_URL;
    const {state} = useLocation();
    const [reservation, setReservation] = useState(undefined);
    const [commentaires, setCommentaires] = useState([]);
    const [trajet, setTrajet] = useState(undefined);
    const [majs, setMajs] = useState([]);

   
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState({
        id: 0,
        pseudo: "",
        nom: "",
        prenom: "",
        email: "",
        facebook: "",
        linkedin: "",
        website: "",
        telephone: "",
        twitter: ""
    });
    console.log(state);
      

    function getMe(){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
                     'Authorization': 'Bearer '+token
        }
        };
        
        fetch(backend_url+'/user/me', requestOptions)
        .then(response => {
            
            return response.json();
        })
        .then(data => {
            setUser({
                id: data.id,
                pseudo: data.pseudo,
                nom: data.nom,
                prenom: data.prenom,
                email: data.email,
                facebook: data.facebook,
                linkedin: data.linkedin,
                website: data.website,
                telephone: data.telephone,
                twitter: data.twitter
            });

        })
    }
      

    useEffect(()=>{

        if (numero !== undefined){
            getRervation(numero);
        }
        if(state?.trajet != undefined){
            setTrajet(state.trajet);
            getMajsTrajets(state.trajet);
        }
        if(state?.reservation != undefined){
            setReservation(state.reservation);
            getComments(state.reservation);
            
        }

       
        getMe();
    }, []);

    function envoyerCommentaire(){

        let commentaire = document.getElementById('description').value;
        if(commentaire == ""){
            toast.error("Le commentaire ne peut pas être vide!");
            return
        }
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        
        },
          body: JSON.stringify({ 
            id_reservation: reservation.id,
            texte: commentaire
            
          },)};
        
        fetch(backend_url+'/commentaire/add', requestOptions)
        .then(response => {

            if (response.ok) {
                toast.success("Le commentaire a bien eté ajouté!");
                document.getElementById('description').value = '';
                getComments(reservation);
              }
            return response.json();
        })
    }

    function getComments(res){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        
        },
        };
        
        fetch(backend_url+'/commentaire/reservation/'+res.id, requestOptions)
        .then(response => {

            return response.json();
        })
        .then(data => {
          setCommentaires(data);
        })
    }

    function getRervation(numero){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        
        },
        };
        
        fetch(backend_url+'/reservation/get?numero='+numero, requestOptions)
        .then(response => {

            return response.json();
        })
        .then(data => {
          setTrajet(data.trajet);
          setReservation(data);
          getComments(data);
          getMajsTrajets(data.trajet);
        })
    }

    function getMajsTrajets(trajet) {
        
        let token = localStorage.getItem("token");
        console.log("In Maj");

        if (state?.trajet != undefined){
          setTrajet(state.trajet);
        }

        console.log("In after return");
        
        
        const requestOptions = {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        }
        };
        
        fetch(backend_url+'/majtrajet/'+trajet.id, requestOptions)
        .then(response => {
            return response.json();
        })
        .then(data => {
          console.log(data);
          setMajs(data);
        })
        .catch(error => {
         console.log(error);
        });
      }

    function getFormattedDate(date_ajout){
        // Format date with Moment.js
        let formattedDate = "";
        try {
            formattedDate = moment.utc(date_ajout).fromNow();
        } catch (error) {
            console.error("Error parsing date:", error);
            formattedDate = "Invalid Date";
        }
        return formattedDate;
    }

   
    return (
        <div>
            <ToastContainer />
            <body className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
                <NavbarV2 />
                <section className="py-28-b w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"
                    id="home"
                    style={{backgroundSize:'cover'}} 
                    >
                    <div className="absolute inset-0 bg-slate-950/80 navbar-background"></div>

                    <div className="container">
                        <div className="grid grid-cols-1 pb-8 text-center mt-10">
                            <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Détails de la reservation {}</h3>
                            <h1 className='text-white'>Numéro du trajet: {trajet && trajet.numero}</h1>
                            <div>
                                <h1 className='text-white'>Statut de la réservation:
                                    {reservation && reservation.statut == "ACCEPTE" &&
                                        <div className="status-badge-actif">Accepté</div>
                                    }
                                    {reservation && reservation.statut !== "ACCEPTE"  && reservation.statut !== ""  &&
                                        <div className="status-badge-annuler">{reservation.statut}</div>
                                    }
                                    {reservation && reservation.statut == ""  &&
                                        <div className="status-badge-actif">Envoyée</div>
                                    }
                                </h1>
                            </div>
                        </div>
                        
                    </div>

                  
                    { trajet &&
                        <div className="event-card container">
                            <div className="event-details">
                            <h2 className="event-title">{trajet.ville_depart}</h2>
                            <div className="event-date-wrapper">
                                <img loading="lazy" src={depart} className="icon" alt="" />
                                <span><Moment format="LL" >{trajet.date_depart}</Moment></span>
                            </div>
                            </div>
                            {trajet.type === "Avion"  &&
                                    <FlightTakeoff className='colismi-icon' sx={{ fontSize: 80, color: '#ffff' }} />

                                    }
                                    {trajet.type === "Fret Aerien"  &&
                                    <FlightTakeoff className='colismi-icon' sx={{ fontSize: 80, color: '#ffff' }} />

                                    }
                                    {trajet.type === "Bateau"  &&
                                    <Boat className='colismi-icon' sx={{ fontSize: 80, color: '#ffff' }} />

                                    }

                            <div className="event-details">
                            <h2 className="event-title">{trajet.ville_arrivee}</h2>
                            <div className="event-date-wrapper">
                                <img loading="lazy" src={arrivee} className="icon" alt="" />
                                <span><Moment format="LL" >{trajet.date_arrivee}</Moment></span>
                            </div>
                            </div>
                        </div>
                 }
                </section>
                <section className="relative" id="about">
                    <div id='content-part'>
                        { reservation &&
                        <div id='header'>
                                    <div className="publication-card">
                                        <img src={user_icon} color="secondary" style={{ fontSize: 20, color: '#5f2972' }}  className='user-avatar' />
                                        <h1>Utilisateur</h1>
                                        <p>{reservation.sender.pseudo}</p>
                                    </div>
                                
                                    <div className="publication-card">
                                        <img src={date} color="secondary" style={{ fontSize: 20, color: '#5f2972' }}  className='user-avatar'  />
                                        <h1>Réservation effectuée</h1>
                                        <p>{getFormattedDate(reservation.date_ajout)}</p>
                                    </div>
                                    <div className="travel-details-item hidden">
                                        <AlternateEmailRoundedIcon color="secondary" style={{ fontSize: 40, color: '#5f2972' }} />
                                        <h1>Contact</h1>
                                        <p>{reservation.sender.email}</p>
                                    </div>
                                    <div className="publication-card">
                                        <img src={poids} color="secondary" style={{ fontSize: 20, color: '#5f2972' }} className='user-avatar'  />
                                        <h1>Poids du colis</h1>
                                        <p>{reservation.kilos} Kg</p>
                                    </div>
                                    <div className="publication-card">
                                        <img src={poids} color="secondary" style={{ fontSize: 20, color: '#5f2972' }} className='user-avatar'  />
                                        <h1>Dimensions</h1>
                                        <p className='dimension-colis'>Longueur: {reservation.longueur}cm</p>
                                        <p  className='dimension-colis'>Largeur: {reservation.largeur}cm</p>
                                        <p  className='dimension-colis'>Hauteur: {reservation.hauteur}cm</p>
                                    </div>
                                    <div className="publication-card">
                                        <img src={prix} color="secondary" style={{ fontSize: 20, color: '#5f2972' }} className='user-avatar' />
                                        <h1>Prix</h1>
                                        <p>{reservation.tarif_kilos} €/Kg</p>
                                    </div>
                        </div>
                        }
                        <div id='content-body'>
                            
                           
                           
                        
                        </div>
                        <div className="details-trajet">
                            { reservation &&
                            <div className="details-trajet-user">
                                <div className="profile-card">
                                    <div className="profile-details">
                                    <img loading="lazy" src={user_icon} className="profile-avatar" alt="Didi's profile picture" />
                                    <h2 className="profile-name"><Link to="/traveler"  state={{ user: reservation.trajet.user }}> {reservation.trajet.user.pseudo}</Link></h2>
                                    <p className="profile-role">{reservation.trajet.user.type}</p>
                                    <div className="profile-divider"></div>
                                    <h3 className="profile-description-title">Description</h3>

                                        {
                                        trajet.user.description &&
                                                <p className="preserve-whitespace profile-description">{reservation.trajet.user.description}</p>
                                        }

                                        {
                                        trajet.user.description === "" &&
                                                <p>Aucune description</p>
                                        }
                                    <div className="profile-divider"></div>
                                    <ul className="profile-info-list">
                                        <li className="profile-info-item">
                                        <img loading="lazy" src={facebook} className="profile-info-icon" alt="Info icon" />
                                            
                                                {( reservation.trajet.user.facebook === "" || reservation.trajet.user.facebook === null ) &&
                                                    <span className="profile-info-text">Non renseigné</span>
                                                }
                                                {trajet.user.facebook !=="" &&
                                                    <span className="profile-info-text">{reservation.trajet.user.facebook}</span>
                                                }
                                            
                                        </li>
                                        <li className="profile-info-item">
                                        <img loading="lazy" src={twitter} className="profile-info-icon" alt="Info icon" />
                                            
                                                {( reservation.trajet.user.twitter === "" || reservation.trajet.user.twitter === null ) &&
                                                    <span className="profile-info-text">Non renseigné</span>
                                                }
                                                {reservation.trajet.user.twitter !=="" &&
                                                    <span className="profile-info-text">{trajet.user.twitter}</span>
                                                }
                                            
                                        </li>
                                        <li className="profile-info-item hidden">
                                        <img loading="lazy" src={whatsapp} className="profile-info-icon" alt="Info icon" />
                                            
                                                {( reservation.trajet.user.telephone === "" || reservation.trajet.user.telephone === null ) &&
                                                    <span className="profile-info-text">Non renseigné</span>
                                                }
                                                {reservation.trajet.user.telephone !=="" &&
                                                    <span className="profile-info-text">{reservation.trajet.user.telephone}</span>
                                                }
                                        </li>
                                    </ul>
                                    </div>
                                    <button className='bouton-voir'> <Link to="/traveler"  state={{ user: reservation.trajet.user }}>Voir le profil</Link></button>
                            </div>

                                
                            </div>
                            }
                            <div className="details-trajet-post">
                                    <strong className='profile-description-title'>Description de la réservation</strong>
                                    <p>{reservation &&
                                    reservation.commentaire}</p>
                                    <br/>

                                    <div id="maj-trajet">
                                <h1 className='profile-description-title'>Mises à jour du transporteur</h1>
                                    { majs.length === 0 &&
                                        <p> Il n'y a aucune mise à jour </p>
                                    }
                                    {
                                        majs.map(majtrajet =>(
                                            <div className="majtrajet" key={majtrajet.id}>
                                                <div className="majtrajet-left">
                                                    <div>
                                                        <CampaignIcon className='colismi-icon' sx={{ fontSize: 40, color: '#725f29' }} />

                                                    </div>
                                                    <div>
                                                    <p>{getFormattedDate(majtrajet.date_ajout)}</p>
                                                    </div>
                                                    
                                            </div>
                                            <div className="majtrajet-right">
                                                    {majtrajet.commentaire}
                                                </div>
                                            </div>
                                        ))
                                    }
                                 </div>
                            <div id='historic-discussion'>
                                <br/>
                                <strong className='profile-description-title'>Historique de discussion</strong>
                                <br/>
                                { commentaires.length === 0 &&
                                    <p>Aucun element dans la discussion</p>
                                }
                            
                                {
                                    commentaires.map(commentaire => (

                                        <div className="commentaire-card" key={commentaire.id}>
                                            <div className="commentaire-header">
                                                <div className="commentaire-info">
                                                <div className="com-user-details">
                                                    <img src={profil} alt="User avatar" className="com-user-avatar" />
                                                    <div className="com-user-name-rating">
                                                    { commentaire.id_user === reservation.sender.id &&
                                                        <div className="com-user-name"> <Link to="/traveler"  state={{ user: reservation.sender }}> <strong>{reservation.sender.pseudo}</strong></Link></div>

                                                    }
                                                    { commentaire.id_user === reservation.trajet.user.id &&
                                                        <div className="com-user-name"> <Link to="/traveler"  state={{ user: reservation.trajet.user }}> <strong>{reservation.trajet.user.pseudo}</strong></Link></div>

                                                    }
                                                    <div className="rating-container hidden">
                                                        <div className="rating-value hidden">4.5</div>
                                                        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/d2a17639e223d5c69dfbd0af2899b20dfa8ea9fa06dbcb8d5c1d8d920e8bbeed?apiKey=0b65d8b49c0846d0bfbbad44662cd24e&" alt="Rating star" className="com-rating-star" />
                                                        <div className="rating-count hidden">(6)</div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="commentaire-date">
                                                    <img src={date} alt="Date icon" className="date-icon" />
                                                    <div className="date-text">{getFormattedDate(commentaire.date_ajout)}</div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="commentaire-content">
                                                <img src={description} alt="Quote icon" className="quote-icon" />
                                                <div className="commentaire-text">
                                                "{commentaire.texte}"
                                                </div>
                                            </div>
                                        </div>
     
                                    ))
                                }
                            </div>
                            <div>
                                <br/>
                                <div className="mb-5">
                                    <label htmlFor="description" className="form-label font-medium"><strong>Laissez un commentaire:</strong></label>
                                    <textarea required name="description" id="description" className="form-input w-full py-2 px-3 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2 textarea h-28" placeholder=""></textarea>
                                </div>
                                <button id="submit" onClick={envoyerCommentaire} name="send" className="colismi-button-cm">Envoyer</button>
                                
                            </div>

                             </div>

                        </div>

                    </div>
                    
                    
                </section>
                <Footer />
            </body>
            
        </div>
    )
}


/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Navbar from '../../component/navbar'
import Footer from '../../component/footer';
import { ToastContainer } from 'react-toastify';

export default function CGU() {

    const styles = {
        article: {
          marginBottom: "#18px",
        }
      }

    return (
        <div style={styles}>
            <Navbar />
            <ToastContainer />
            <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 bg-slate-950/80"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Conditions générales d'utilisation</h3>
                    </div>
                </div>
            </section>
            <section className="relative lg:py-24 py-16">
                <div style={{width: '80%', margin: 'auto'}}>
                    <article id="presentation-colismi">
                        <h1>Article 1: Présentation Colismi</h1>
                        <p>Colismi est une application dont le but est de rassembler et de mettre en relation les personnes qui ont besoin de transporter des objets (les propriétaires ou possesseurs d'objets) et les personnes qui pourraient porter ces objets (les voyageurs). Ces voyageurs percevront une allocation, pour le service rendu. En aucun cas, Colismi ne sera assimilé à un transporteur de marchandises ou de personnes, un producteur, un importateur ou un annonceur pour ces produits, une entreprise de livraison, un conseiller internet, un journaliste ou un critique.
                        </p>
                        <p>
                        Colismi peut dans le cadre de son activité proposer des services de:
                        
                        </p>
                        <ul>
                            <li>- Livraison de colis à domicile</li>
                            <li>- La commande et réception d’articles</li>
                            <li>- Le renseignement sur des services de transport </li>
                            <li>- Proposer des services de transport via ses partenaires.</li>
                        </ul>
                    </article>
                    <br/>

                    <article id="plateforme-colismi">
                        <h2>Article 2: Plateforme Colismi</h2>
                        <p>L’objectif de Colismi est de créer un réseau pour ses membres et de fournir la plateforme nécessaire pour l’envoi, le suivi et la gestion des colis. Il fournit aussi sous requête, des recommandations pour l’envoi de marchandises ou colis. Colismi est propriétaire d'une plateforme (application mobile et site web) et d'un réseau de personnes. Ces personnes n'ont aucun lien de subordination avec Colismi, ni leurs employés, actionnaires ou propriétaires.</p>
                    </article>
                    <br/>

                    <article id="accord-de-transport">
                        <h2>Article 3: Accord de transport</h2>
                        <p>Le contrat de transport est conclu directement entre le voyageur et le demandeur, sans aucune intervention de Colismi. Colismi n'interfère pas dans la conclusion ni dans l'exécution de l'accord. Par conséquent, Colismi décline toute responsabilité en cas de fraude, de malentendu ou de non-exécution du contrat. Les conditions de paiement sont à définir entre l’envoyeur et le transporteur.</p>
                    </article>
                    <br/>

                    <article id="recommandations-et-limitations">
                        <h2>Article 4: Recommandations et limitations concernant l'article transporté</h2>
                        <p>Il incombe au propriétaire ou au possesseur de l'article et au voyageur de s'assurer, avant la conclusion de l'accord, que l'article transporté peut être légalement transporté et possédé dans le pays de destination, ainsi que le pays de départ et tout pays qui pourrait être traversé pendant le voyage. Les deux parties veilleront également à ce qu'aucun article pouvant être transporté par ou pour un utilisateur ne soit interdit ou restreint dans le pays de destination.</p>
                    </article>
                    <br/>

                    <article id="transparence">
                        <h2>Article 5: Transparence</h2>
                        <p>Il est de la responsabilité du transporteur d’informer l’envoyeur de tout événement pouvant impacter le retard de livraison des articles et ce dans les plus brefs délais.</p>
                    </article>
                    <br/>

                    <article id="acces-application">
                        <h2>Article 6: Accès à l’application</h2>
                        <p>Les services de Colismi sont accessibles depuis l’application du même nom sur la plateforme Google Play et Apple Store. La création de compte est réalisable par tout individu possédant un numéro de téléphone, une adresse mail et une carte d'identification. Colismi se réserve le droit de suspendre temporairement ou définitivement un compte après plusieurs manquements de la part de l’utilisateur.</p>
                    </article>
                    <br/>

                    <article id="services-voyageur">
                        <h2>Article 7: Services voyageur</h2>
                        <p>Le voyageur a le devoir de préciser clairement à l’envoyeur les informations liées à son vol : la date de départ, la date d’arrivée ainsi que les escales faites s’il y en a.</p>
                    </article>
                    <br/>

                    <article id="envoyeur">
                        <h2>Article 8: Envoyeur</h2>
                        <p>Une requête d’envoi de colis doit contenir les informations précises liées aux objets transportés. Ces informations doivent être précisées en détails sur l’application afin de faciliter la collecte et la remise. Cela permettra d’éviter les litiges.</p>
                    </article>
                    <br/>

                    <article id="destinataire">
                        <h2>Article 9: Le destinataire</h2>
                        <p>Lors de la remise du colis, le voyageur a le droit de réclamer la pièce d’identité de celui-ci afin de s’assurer qu’il s’agit bien de celle définie dans l’application mobile.</p>
                    </article>
                    <br/>

                    <article id="conditions-avant-transport">
                        <h2>Article 10: Conditions avant le transport</h2>
                        <p>Tout objet cassable ou fragile doit être emballé en présence du demandeur et du voyageur afin de constater le bon état de l'objet avant son transport. Il est recommandé de tester l'appareil électronique pour s'assurer de son bon fonctionnement avant le voyage. Il est également recommandé de prendre des photos de l'article transporté et de l'emballage avant le voyage afin d'en apporter la preuve en cas de litige; Le voyageur a le droit et le devoir d'examiner attentivement l'article et l'emballage avant le voyage pour s'assurer qu'il ne cache pas ou ne contient pas de matériel illégal ou interdit. Si le voyageur soupçonne que l'article transporté peut contenir du matériel illégal ou interdit, le voyageur a le devoir de refuser de procéder au transport. Le voyageur a le devoir de s’assurer de la liste complète des articles qu’il transporte afin de faciliter la remise. Le voyageur a le droit de demander à retirer les emballages d’un colis afin de s’assurer de son intégrité ou de sa nature.</p>
                    </article>
                    <br/>

                    <article id="transports-risques">
                        <h2>Article 11: Transports et risques</h2>
                        <p>Il est du devoir du voyageur de s'assurer que le transport est effectué avec prudence et sans aucun risque d'abîmer, de casser ou d'endommager l'article transporté. Le voyageur s'assurera également que le transport sera également effectué conformément à la recommandation sanitaire et légale. Colismi ne pourra être tenu responsable de la perte, de la détérioration ou du vol de l'article transporté pendant toute la durée du voyage. Le temps de déplacement commence lorsque l'article est récupéré par les voyageurs et se termine lorsqu'il est livré à la personne destinataire. En tant que demandeur du voyage, le demandeur sera responsable de toute réclamation ou litige lié aux douanes ou à la réglementation résultant du transport. Le voyageur est considéré comme le gardien de l'objet. Il est dépositaire de la possession précaire de l'objet transporté. Par conséquent, il sera tenu responsable de toute perte ou dommage causé par l'article ou son comportement pendant le transport.</p>
                    </article>
                    <br/>

                    <article id="remise-colis-avant-voyage">
                        <h2>Article 12: Remise du colis avant voyage</h2>
                        <p>Le voyageur et l’envoyeur choisissent un lieu pour la réception du colis selon leur convenance. Cela se fait de préférence dans un lieu où les vérifications de la nature du colis et des éléments constitutifs peuvent être faites.</p>
                    </article>
                    <br/>

                    <article id="remise-colis-apres-voyage">
                        <h2>Article 13: Remise du colis après voyage</h2>
                        <p>Le destinataire d’un colis doit vérifier si les éléments reçus correspondent à ce qui a été envoyé avant validation de la remise du colis sur la plateforme. Cela faisant foi du bon déroulement de la transaction.</p>
                    </article>
                    <br/>

                    <article id="saisie-douaniere">
                        <h2>Article 14: Saisie douanière</h2>
                        <p>Il est de la responsabilité de l’envoyeur de gérer les frais douaniers liés à son colis. En aucun cas cela sera à la charge du voyageur. Les démarches liées à la récupération du colis sont donc du ressort de l’envoyeur. Le voyageur de son côté devra fournir les informations et documents nécessaires pour l’identification de son colis auprès des autorités douanières.</p>
                    </article>
                    <br/>

                    <article id="cout-transport">
                        <h2>Article 15: Coût de transport</h2>
                        <p>Le coût est défini par le voyageur lors de la publication de son offre de voyage. Le voyageur se réserve le droit d’accepter les négociations ou effectuer des réductions ponctuelles.</p>
                    </article>
                    <br/>

                    <article id="defaut-livraison">
                        <h2>Article 16: Défaut de livraison</h2>
                        <p>Un défaut de livraison sera établi lorsque :  <br/>
                            - un article n'est pas livré totalement ou partiellement <br/>
                            - un article est livré mais est endommagé. <br/>
                            Le voyageur a le devoir de fournir les explications et de mettre en place les moyens nécessaires afin de réparer le préjudice. Colismi décline toute responsabilité en cas de non-livraison ou de livraison défectueuse d'un article.</p>
                    </article>
                    <br/>

                    <article id="gestion-litiges">
                        <h2>Article 17: Gestion des litiges</h2>
                        <p>Tous les litiges découlant de la contraction, de l’exécution ou de l’interprétation de la convention de transport ou des conditions de Colismi seront de préférence résolus de manière consensuelle et négociée. Si une telle résolution de litige n’est pas possible pour les services de Colismi ou si l’une des parties n’a pas répondu à une invitation à une résolution consensuelle du litige, le litige sera soumis à la juridiction compétente.</p>
                    </article>
                    <br/>

                    <article id="acceptation-conditions-utilisation">
                        <h2>Article 18: Acceptation des conditions d’utilisation</h2>
                        <p>L'acceptation des présentes conditions générales est matérialisée par une case à cocher dans le formulaire d'inscription. Cette acceptation ne peut être que pleine et entière. L'utilisateur qui n'accepte pas d'être lié par les présentes conditions générales ne doit pas accéder à la plateforme Colismi ni utiliser les Services.</p>
                    </article>
                    <br/>

                    <article id="verification-telephone">
                        <h2>Article 19: Vérification du numéro de téléphone portable</h2>
                        <p>Pour pouvoir effectivement utiliser les Services, l'Utilisateur doit avoir renseigné dans son Compte son numéro de téléphone portable et effectué les opérations nécessaires aux fins de vérification de celui-ci par Colismi, selon les modalités qui lui seront indiquées. Colismi se réserve la possibilité de demander à l'Utilisateur toute information et tout document complémentaire qu'elle jugera utiles.</p>
                    </article>
                    <br/>

                    <article id="exactitude-informations">
                        <h2>Article 20: Exactitude des informations</h2>
                        <p>L'Utilisateur garantit que toutes les informations qu'il fournit à Colismi sont exactes, à jour et sincères et ne sont entachées d'aucun caractère trompeur. Il s'engage à mettre à jour ces informations dans son Espace Personnel en cas de modifications, afin qu'elles correspondent toujours aux critères susvisés. L'Utilisateur est informé et accepte que les informations saisies aux fins de création ou de mise à jour de son Compte vaillent preuve de son identité. Les informations saisies par l'utilisateur l'engagent dès leur validation. Colismi se réserve le droit de demander tout document justificatif de l’identité de l’utilisateur afin d'accéder à des fonctions de l’application.</p>
                    </article>
                </div>
            </section>
            <Footer />
        </div>
    )
}
import React from 'react'
import { animateScroll as scroll } from 'react-scroll';

export default function Switcher() {

    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 500,
            smooth: true,
        });
    };

    return (
        <>
           

            <a href='#' onClick={scrollToTop} id="back-to-top" className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-violet-600 text-white justify-center items-center"><i className="uil uil-arrow-up"></i></a>
        </>
    )
}
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarV2 from '../../../component/navbar-v2';
import Footer from '../../../component/footer';
import StarRating from '../../../component/StarRating';
import LoginModal from '../../../component/login_modal.js';
import { ToastContainer, toast } from 'react-toastify';
import styles from "../detail-trajet/detail-trajet.css";
import profil from '../../../assets/images/colismi/trajet/user.svg';
import contact from '../../../assets/images/contact.svg';
import 'react-toastify/dist/ReactToastify.css';
import './add-review.css';
import moment from 'moment-timezone';
moment.locale('fr');

export default function AddReview() {
    const backend_url  = process.env.REACT_APP_BACKEND_URL;
    const location = useLocation();
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [reviewToken, setReviewToken] = useState(null);
    const [pseudoReviewee, setPseudoReviewee] = useState(null);
    const [trajetNum, setTrajetNum] = useState(null);
    const [error, setError] = useState('');

    const [reviews, setReviews] = useState([]);
    const [meanRating, setMeanRating] = useState(0); // State to hold mean rating

    // const [showLoginModal, setShowLoginModal] = useState(false);


    const [formData, setFormData] = useState({
        note: 0, // initial rating value
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const review_token = queryParams.get('token');
        const pseudo_reviewee = queryParams.get('pseudo_reviewee');
        const trajet_num = queryParams.get('trajet_num');
        const retrieved_token = localStorage.getItem("token");      

        // if (!review_token) {
        //     toast.error('Vous n avez pas un lien valide');
        //     navigate('/'); // Redirect to login page
        // } 
        if (!retrieved_token){
            toast.error('Vous devez vous connecter pour laisser un avis !');                       
        }

        setReviewToken(review_token);
        setPseudoReviewee(pseudo_reviewee);
        setTrajetNum(trajet_num);
        setToken(retrieved_token);

    }, []);  

    function handleLoginSuccess(){
        // Set the token after successful login
        // localStorage.setItem("token", userToken);
        const retrieved_token = localStorage.getItem("token");
        setToken(retrieved_token);
        // Reload the page to trigger the useEffect and re-fetch data with the new token
        // window.location.reload();        
    }

    function handleAnnuler(){
        navigate('/'); // Redirect to login page
    }

    function calculateMeanRating(reviews){
        if (reviews.length > 0) {
            const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
            const mean = totalRating / reviews.length;
            setMeanRating(mean);
            return mean;
        } else {
            setMeanRating(0);
            return 0;
        }
    }              

    async function fetchReviews(reviewee_id) {    
        try {
            const response = await fetch(backend_url + '/reviews/user?id=' + reviewee_id);
            
            if (!response.ok) {
                throw new Error('Network error');
            }
    
            const data = await response.json();
            setReviews(data);
            calculateMeanRating(data); // Pass data directly to avoid stale state issues
            return data; // Return the fetched data
    
        } catch (error) {
            console.error('Error fetching reviews:', error);
            toast.error("Impossible de récupérer la liste des reviews, veuillez réessayer plus tard");
            return []; // Return an empty array on error
        }
    }
    
    async function sendReview() {
        const text = document.getElementById('comments').value;
        const rating = formData.note;
    
        if (rating === 0 || text === '') {
            setError('Veuillez fournir une note et un commentaire.');
            console.error('[Erreur Add-review] Veuillez fournir une note et un commentaire.');
            toast.error('Veuillez fournir une note et un commentaire.');
            return;
        }
    
        const review = {
            text,
            rating
        };
    
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(review)
        };
    
        try {
            const response = await fetch(`${backend_url}/reviews/add?review_token=${reviewToken}`, requestOptions);
    
            if (response.ok) {
                toast.success('Avis ajouté avec succès ! Merci ');

                const data = await response.json();
                // console.log('Response data:', data);
        
                // Assuming the response contains reviewee.id
                const revieweeId = data.reviewee.id;
                // console.log('Reviewee ID:', revieweeId);
        
                // Fetch reviews for the reviewee and navigate to user-review page
                if (revieweeId) {
                    const updatedReviews = await fetchReviews(revieweeId);
                    const newMean = calculateMeanRating(updatedReviews);
                    navigate('/user-review', { state: { user_reviews: updatedReviews, user_mean_rating: newMean, user_pseudo: data.reviewee.pseudo } });
                } else {
                    navigate('/user-review', { state: { user_reviews: reviews, user_mean_rating: meanRating, user_pseudo: data.reviewee.pseudo } });
                }

            } else {

            // Extract error details from response
            const errorData = await response.json();
            const errorDetail = errorData.detail || 'Une erreur est survenue.';

            switch (response.status) {
                case 401:
                    if (errorDetail === 'Token has expired') {
                        toast.error('Votre session a expiré. Veuillez vous reconnecter.');
                    } else if (errorDetail === 'Invalid token') {
                        toast.error('Le token est invalide. Veuillez vérifier le lien.');
                    }
                    break;
                case 403:
                    toast.error('Vous n\'êtes pas autorisé à écrire cet avis.');
                    break;
                case 400:
                    if (errorDetail === 'Review already exists') {
                        toast.error('Vous avez déjà laissé un avis pour ce trajet.');
                    }
                    break;
                default:
                    toast.error('Erreur lors de l\'ajout !');
                    break;
            }

            }
    
        } catch (error) {
            toast.error('Erreur lors de l\'ajout de l\'avis.');
            console.error('Error:', error);
        }
    }
    

    return (
        <div className={styles}>
            <NavbarV2 />
            <ToastContainer />

            {token ? (
                <>
                    <section className="top-display py-3 w-full table relative bg-[url('../../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{ backgroundSize: 'cover' }} id="home">
                        <div className="absolute inset-0 bg-slate-950/80 navbar-background"></div>
                        <div className="container">
                            <div className="grid grid-cols-1 pb-8 text-center mt-10">
                                <h5 className="mb-3 font-medium leading-normal text-3xl mt-10 text-white">Ajouter un avis pour le trajet {trajetNum} avec {pseudoReviewee}</h5>
                                {/* <h5 className="mb-3 font-medium leading-normal text-3xl mt-10 text-white">Ajouter un avis pour le trajet {numTrajet} avec {pseudoReviewee}</h5> */}
                                <div className="flex justify-center items-center mt-4">
                                    <img loading="lazy" src={profil} className="profile-avatar" alt="Didi's profile picture" />
                                    {/* <h2 className="profile-name-review">{pseudoReviewee}</h2> */}
                                </div>                    
                            </div>
                        </div>
                    </section>

                    <section className="relative">
                        <div className="container">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                                <div className="lg:col-span-7 md:col-span-6">
                                    <img src={contact} alt="" />
                                </div>

                                <div className="lg:col-span-5 md:col-span-6">
                                    <div className="lg:ms-5">
                                        <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                            <h3 className="mb-6 text-2xl leading-normal font-medium titre-about">Votre avis !</h3>

                                            <form name="myForm" id="myForm">
                                                <p className="mb-0" id="error-msg">{error}</p>
                                                <div id="simple-msg"></div>
                                                <div className="grid grid-cols-1">
                                                    <div className="mb-5">
                                                        <label htmlFor="note" className="form-label font-medium">Note (sur 5):</label>
                                                        <StarRating
                                                            name="note"
                                                            value={formData.note}
                                                            onChange={handleFormChange}
                                                        />
                                                    </div>                                            

                                                    <div className="mb-5">
                                                        <label htmlFor="comments" className="form-label font-medium">Commentaire:</label>
                                                        <textarea name="comments" id="comments" className="input-wrapper w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2 textarea h-28" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </form>
                                            <button onClick={sendReview} id="submit" name="send" className="w-full colismi-button rounded-md">Envoyer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <ContactData /> */}
                    </section>

                </>

            ) :
            (
                <> 
                    <section className="top-display py-3 w-full table relative bg-[url('../../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{ backgroundSize: 'cover' }} id="home">
                        <div className="absolute inset-0 bg-slate-950/80 navbar-background"></div>
                        <div className="container">
                            <div className="grid grid-cols-1 pb-8 text-center mt-10">
                                <h5 className="mb-3 font-medium leading-normal text-3xl mt-10 text-white">Ajouter un avis ...</h5>                    
                            </div>
                        </div>
                    </section>
                    <LoginModal onLoginSuccess={handleLoginSuccess} onClose={handleAnnuler}/>
                </>
            )            
            
            }                       

            <Footer />
        </div>
    );
}

import React from 'react'
import OurServices from '../component/our-services';
import About from '../component/about';
import Navbar from '../component/navbar';
import Footer from '../component/footer';

export default function AboutUs() {
    return (
        <div>
            <body className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
                <Navbar />
                <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"
                    id="home">
                    <div className="absolute inset-0 bg-slate-950/80"></div>

                    <div className="container">
                        <div className="grid grid-cols-1 pb-8 text-center mt-10">
                            <h3 className="font-medium leading-normal text-3xl mt-10 text-white">A propos de colismi</h3>
                        </div>
                    </div>
                </section>
                <section className="relative md:py-24 py-16" id="about">
                    <About />
                    <OurServices />
                    
                </section>
                <Footer />
            </body>
        </div>
    )
}


import React, { useState } from 'react';
import PropTypes from 'prop-types';  // Import PropTypes
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo_light from '../assets/images/colismi_logo_small.png';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ onLoginSuccess, onClose }) => {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const checkUserLogin = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                email: email,
                password: password
            })
        };

        fetch(`${backend_url}/user/login`, requestOptions)
            .then(response => {
                if (response.ok) {
                    toast.success("Login OK");
                    return response.json();
                } else {
                    toast.error("Vos identifiants ne sont pas corrects");
                    throw new Error('Login failed');
                }
            })
            .then(data => {
                localStorage.setItem('token', data.access_token);
                localStorage.setItem('pseudo', data.pseudo);
                onLoginSuccess();  // Call the success handler passed as a prop
                // onLoginSuccess(data.access_token);  // Call the success handler passed as a prop
                // onClose(); // Close the modal after successful login
            })
            .catch(error => {
                console.error(error);
                onClose();
            });
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            {/* <ToastContainer limit={1}/> */}
            <div className="w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-gray-800">
                <div className="grid grid-cols-1 text-center">
                    <img src={logo_light} alt="Colismi Logo" className="mx-auto mb-4" />
                    <h5 className="mb-8 text-xl dark:text-white font-medium">Connexion</h5>
                    <div className="text-start">
                        <div className="grid grid-cols-1">
                            <div className="mb-4">
                                <label className="form-label font-medium" htmlFor="LoginEmail">Adresse mail:</label>
                                <input 
                                    id="LoginEmail" 
                                    type="email" 
                                    className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" 
                                    placeholder="" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className="mb-4">
                                <label className="form-label font-medium" htmlFor="LoginPassword">Mot de passe:</label>
                                <input 
                                    id="LoginPassword" 
                                    type="password" 
                                    className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" 
                                    placeholder="" 
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>

                            <div className="mb-4 w-full">
                                <button className="colismi-button w-full" onClick={checkUserLogin}>Connexion</button>
                            </div>

                            <div className="text-center">
                                <span className="text-slate-400 me-2">Vous n avez pas de compte ?</span> 
                                <button onClick={() => navigate('/signup')} className="text-slate-950 dark:text-white font-bold">
                                    Création
                                </button>
                            </div>

                            <div className="text-center mt-4">
                                <button onClick={onClose} className="text-slate-400 font-bold">
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Define the prop types for the component
LoginModal.propTypes = {
    onLoginSuccess: PropTypes.func.isRequired,  // Ensure that onLoginSuccess is required
    onClose: PropTypes.func.isRequired          // Ensure that onClose is required
};

export default LoginModal;

import React from 'react';
import styles from './typing.css'

export default function Typing(){

    return(
        <div className={styles}>
            <div className="typing">
                <div className="typing__dot"></div>
                <div className="typing__dot"></div>
                <div className="typing__dot"></div>
            </div>
        </div>
    )
}
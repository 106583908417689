import React from 'react'
import Navbar from '../component/navbar'
import BlogData from '../component/blog-data'
import Footer from '../component/footer'


export default function Blogs() {
    return (
        <>
            <Navbar />
            <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"  style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 bg-slate-950/80"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Blog</h3>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16" id="blog">
                <BlogData />
                
            </section>
            <Footer />
        </>
    )
}

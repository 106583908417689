/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import NavbarV2 from '../../../component/navbar-v2.js'
import Footer from '../../../component/footer.js';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import "react-datepicker/dist/react-datepicker.css";
import "../travel-search/travel-search.css";
import './parcels.css';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '@mui/material/Pagination';
import no_result from '../../../assets/images/colismi/Illustration-erreur.svg'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import RequestItemV2 from '../../v2/request/request-item.js';
import ModalReponse from '../../v2/modal-reponse/modal-reponse.js';



export default function ParcelSearch() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const item_per_page = 10;
    const [villes, setVilles] = useState([]);
    const [requetes, setRequetes] = useState([]);
    const [display_requetes, setDisplayedRequetes] = useState([]);
    const [colis, setColis] = useState(undefined);
    const [open, setOpen] = React.useState(false);
    const [isLoggedin, setIsLoggedin] = useState(false);


    const handleClickOpen = ( data) => {
      console.log(data);
      setOpen(true);
      setColis(data);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    function gererEnvoiReponse(){
      console.log(colis);
      let token = localStorage.getItem("token");
      const commentaire = document.getElementById('message-input').value;
      //const email = document.getElementById('email-reponse').value;
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token
         },
        body: JSON.stringify({ 
          id: 0,
          email: "",
          description: commentaire,
          telephone: '',
          id_colis: colis.id
        })};
      
      fetch(backend_url+'/req_reponses/add', requestOptions)
      .then(response => {
          if (response.ok){
            /**when the response succeeds */
            var id = localStorage.getItem("id");
            localStorage.setItem("req/"+id+""+colis.id,"Répondu");

          }
          return response.json();
      })
      .then(data => {
        if (data.detail != undefined){
          toast.erreur(data.detail);
        }
        toast.success("Demande bien enregistrée!");
        document.getElementById('message-input').value = "";
        //document.getElementById('email-reponse').value = "";
        handleClose();

      })
        
    }

    function search(ville_depart, ville_arrivee) {
      // GET request using fetch with set headers

      let token = localStorage.getItem("token");
      let headers = { 'Content-Type': 'application/json' };
      let endpoint = "/colis/search";
      if (token !== null){
          setIsLoggedin(false);
          headers = { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
        };
        endpoint = "/colis/search/connected"
      }
      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ 
          ville_depart: ville_depart,
          ville_arrivee: ville_arrivee})};
      
      fetch(backend_url+endpoint, requestOptions)
      .then(response => {
          return response.json();
      })
      .then(data => {
          setRequetes(data);
          setDisplayedRequetes(requetes);
          console.log(data);
      })
      
      
  }
  function getAllColis() {
    let token = localStorage.getItem("token");
    let headers = { 'Content-Type': 'application/json' }
    let endpoint = "/colis/all";
    if (token !== null){
        headers = { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token
      }
      endpoint = "/colis/all/connected";
    }
    const requestOptions = {
      method: 'GET',
      headers: headers,
      };
    
    fetch(backend_url+endpoint, requestOptions)
    .then(response => {
        return response.json();
    })
    .then(data => {
        setRequetes(data);
        setDisplayedRequetes(data.slice(0, item_per_page));
    })
  
  }
   

    useEffect(() => {
      document.title = "Colismi - Recherchez des demandes";

        async function getVilles() {
            try {
              const response = await fetch(backend_url+'/villes');
              const data = await response.json();
              setVilles(data);
            } catch (error) {
              console.error(error);
            }
        }

        getVilles();
       // getColis();
        getAllColis()

        let token = localStorage.getItem("token");
        if(token === undefined){
          setIsLoggedin(false)
        }else{
          setIsLoggedin(true)
        }
        
      }, []);

    function searchColis(){
        const depart = document.getElementById('input-depart').value;
        const arrivee = document.getElementById('input-arrivee').value;
        console.log(arrivee);
        
        
        if(depart === '' && arrivee === ''){
          return getAllColis();
        }
        search(depart, arrivee);
        
        
    }


    function handlePagination(event , page){

      console.log("chaanged "+page);
      //setRequetes()
      setDisplayedRequetes(requetes.slice((page-1)*item_per_page, page*item_per_page));
    }

    return (
        <div>
            <NavbarV2 />
            <ToastContainer />
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')]  bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h6 className="mb-3 font-medium leading-normal text-3xl mt-5 text-white">Vous n'avez pas trouvé de transporteur ?</h6>
                        <h6 className="mb-3 font-medium leading-normal text-sm mt-1 text-white">Déposez une demande pour augmenter vos chances</h6>
                    </div>
                </div>
                <div className="container-search">
                            
                                            
      

                 </div>
                 <div className="container-edit">
                          
                 </div>
            </section> 
            <section>
                <div className="create-annonce">
                         <button className="colismi-button-nobg boutons-action py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center text-white rounded-md" style={{backgroundColor:'#3ebc6e'}} > <Link to="/travel-add">Je propose un trajet</Link></button>
                         <button className="colismi-button-nobg boutons-action py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center text-white rounded-md" style={{backgroundColor:'#3ebc6e'}}> <Link to="/colis-request">Déposer une demande</Link></button>
                 </div>
                <br />
                <br />
                <div className='main-content-zone'>
                   <div className='sidebar-zone'>
                    <p>Effectuez votre recherche ici</p>
                   <Autocomplete
                                    className='autocomplete'
                                    disablePortal
                                    id="input-depart"
                                    options={villes}
                                    sx={{ minWidth: 300, zIndex:0 }}
                                    getOptionLabel={(option) => option.emoji + " " + option.nom}
                                    renderInput={(params) => <TextField {...params} label="Ville de départ du colis"  variant="outlined"   style={{ backgroundColor: 'white' }} />}
                                    />
                            <Autocomplete
                                    className='autocomplete'
                                    disablePortal
                                    id="input-arrivee"
                                    options={villes}
                                    sx={{ minWidth: 300, zIndex:0 }}
                                    getOptionLabel={(option) => option.emoji + " " + option.nom}
                                    renderInput={(params) => <TextField {...params} label="Ville d'arrivée du colis"  variant="outlined"   style={{ backgroundColor: 'white' }} />}
                                    />   
                            <Button onClick={searchColis} className="colismi-button">Recherche</Button>
                   </div>
                   <div className='body-zone'>
                   { requetes.length>0? (
                          <div>{
                                  display_requetes.map((item, index) =>(
                                    <RequestItemV2
                                      {...item}
                                      colis={item}
                                      onClick={ () => handleClickOpen(item)}
                                      key={index}
                                    />
                                ))
                              }
                              <div className='container'>
                                  <Pagination count={Math.ceil(requetes.length/item_per_page)} color="secondary"  onChange={handlePagination}/>
                              </div>
                        </div>
                         )

                        :(
                          <div>
                            <p className='text-no-result text-slate-400 dark:text-slate-300'>Aucun resultat trouvé</p>
                          <div className='container no-result'>

                              
                              <img src={no_result} className='image-no-result'/>

                             

                          </div>
                          <div className='extra-action-no-result'>
                          </div>
                          
                        </div>
                          
                        )
                        }
                   </div>

                </div>
                <div className='ccont'>
                        
                </div>
                
                
            </section>
            <Footer />

      <Dialog
        open={false}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle
        sx={{
          backgroundColor: '#d7cce6'

        }}
        >Prise de contact</DialogTitle>
        {isLoggedin &&
        <DialogContent>
          <DialogContentText>
            Vous avez la reponse à la demande ? Laissez votre message
          </DialogContentText>

          <TextField
            autoFocus
            required
            margin="dense"
            id="commentaire-reponse"
            name="commentaire"
            label="commentaire"
            type="text"
            multiline
            fullWidth
            minRows={4}
            maxRows={6}
            variant="standard"
          />
        
        </DialogContent>
        }
        {!isLoggedin &&
          <DialogContent>
          <DialogContentText>
            Conncectez vous afin de pouvoir répondre à l'utilisateur
          </DialogContentText>

          <button className='colismi-button-cm'><Link to='/login'> Connexion </Link> </button>
        
        </DialogContent>

        }
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          {isLoggedin &&
              <Button onClick={gererEnvoiReponse}>Envoyer</Button>
          }
        </DialogActions>
      </Dialog>
      <ModalReponse
         open={open}
         func_close={handleClose}
         func_valider={gererEnvoiReponse}
         colis={colis}
        />
        </div>
        
    )
}

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

import styles from './ma-reservation.css';
import {React} from 'react';
import date from '../../../assets/images/colismi/trajet/date.svg';
import fleche from '../../../assets/images/colismi/trajet/fleche.svg';
import depart from '../../../assets/images/colismi/trajet/depart_couleur.svg';
import arrivee from '../../../assets/images/colismi/trajet/arrivee_couleur.svg';
import prix from '../../../assets/images/colismi/trajet/prix.svg';
import description from '../../../assets/images/colismi/trajet/description.svg';
import charge from '../../../assets/images/colismi/trajet/charge.svg';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import moment from 'moment-timezone';
import HeightIcon from '@mui/icons-material/Height';
import WidthFullOutlinedIcon from '@mui/icons-material/WidthFullOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
moment.locale('fr');

export default function MaReservation({reservation, func_annuler, func_voir}){

    // Format date with Moment.js
    let formattedDate = "";
    try {
        formattedDate = moment.utc(reservation.date_ajout).fromNow();
    } catch (error) {
        console.error("Error parsing date:", error);
        formattedDate = "Invalid Date";
    }    

    return (
        <div className={styles}>
            <div className="demande-card">
            <div className="demande-header">
                <div className="demande-location-container">
                <div className="location-info">
                    <div className="location-wrapper">
                         <img src={depart} alt="Departure location icon" className="location-icon" />
                         <div className="location-name">{reservation.trajet.ville_depart}</div>
                    </div>
                    <div className="date">{reservation.trajet.date_depart}</div>
                </div>
                <img loading="lazy" src={fleche} className="arrow-icon" alt="Arrow icon" />
                <div className="location-info">
                    <div className="location-wrapper">
                         <img src={arrivee} alt="Departure location icon" className="location-icon" />
                         <div className="location-name">{reservation.trajet.ville_arrivee}</div>
                    </div>
                    <div className="date">{reservation.trajet.date_arrivee}</div>
                </div>
                </div>
                <div className="review-date">
                    {reservation.statut === "ACCEPTE"  &&
                        <div className="status-badge-actif">Accepté</div>

                    }
                    {reservation.statut !== "ACCEPTE"  && reservation.statut !== ""  &&
                        <div className="status-badge-annuler">{reservation.statut}</div>
                    }
                    { reservation.statut == ""  &&
                        <div className="status-badge-actif">Envoyée</div>
                    }
                </div>
            </div>
            <div className="demande-info-container">
            <div className="shipment-info">
            <div className="info-list">
                <div className="info-item">
                    <img src={charge} alt="Capacity icon" className="info-icon" />
                    <div className="info-text">Poids: {reservation.kilos}kg</div>
                </div>
                <div className="info-item">
                    <img src={prix} alt="Price icon" className="info-icon" />
                    <div className="info-text">Prix: {reservation.tarif_kilos}€/Kg</div>
                </div>
                <div className="info-item">
                    <StraightenOutlinedIcon/>
                    <div className="weight-text">Longueur: {reservation.longueur}cm</div>
                </div>
                <div className="info-item">
                    <WidthFullOutlinedIcon/>
                    <div className="weight-text">Largeur: {reservation.largeur}cm</div>
                </div>
                <div className="info-item">
                    <HeightIcon/>
                    <div className="weight-text">Hauteur: {reservation.hauteur}cm</div>
                </div>
            </div>
        </div>
                <div className="demande-description-container">
                <img loading="lazy" src={description} className="quote-icon" alt="Quote icon" />
                <div className="demande-description-text">
                    "{reservation.commentaire}"
                </div>
                </div>
            </div>
            <div className="demande-footer">
                <div className="divider"></div>
                <div className="demande-footer-content">
                <div className="timestamp">
                    <img loading="lazy" src={date} className="clock-icon" alt="Clock icon" />
                    <div className="timestamp-text">{formattedDate}</div>
                </div>
                <div className="demande-action-buttons">
                    <div className="delete-button" tabIndex="0" role="button" onClick={func_annuler}>
                      <ModeEditOutlineOutlinedIcon/>
                      <div className="delete-text">Annuler</div>
                    </div>
                    
                    <div className="demande-view-responses-button" tabIndex="0" role="button" onClick={func_voir}>
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6865b86cd57b4c9afc851f0310c57f83bae05670d1538a6ebec6c3c16f3065de?apiKey=0b65d8b49c0846d0bfbbad44662cd24e&" className="view-responses-icon" alt="View responses icon" />
                         <div className="view-responses-text">Voir les détails</div>
                    </div>
                    
                </div>
                </div>
            </div>
            </div>
            </div>

    );
}